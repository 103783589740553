import { defineMessages } from 'react-intl';

export default defineMessages({
    next: {
        id: 'quoteandbind.ngh.views.directDebit.next',
        defaultMessage: 'Set up Direct Debit'
    },
    termsAndConditionsContent: {
        id: 'quoteandbind.ngh.views.directDebit.termsAndConditionsContent',
        defaultMessage: 'I have read the <a>terms and conditions</a>'
    },
    unableToTakePayment: {
        id: 'quoteandbind.ngh.views.directDebit.unableToTakePayment',
        defaultMessage: 'We can\'t provide a policy for you online'
    },
    unableToProcessContent: {
        id: 'quoteandbind.ngh.views.directDebit.unableToProcessContent',
        defaultMessage: 'In order to set up a Direct Debit with us online all of the above statements must be true. You may still be able to get a quote if you get in touch'
    },
    confirmationTitle: {
        id: 'quoteandbind.ngh.views.directDebit.confirmationTitle',
        defaultMessage: 'Please confirm the following statements to be true'
    },
    ageConfirmation: {
        id: 'quoteandbind.ngh.views.directDebit.ageConfirmation',
        defaultMessage: 'I am over 18'
    },
    instructionOnline: {
        id: 'quoteandbind.ngh.views.directDebit.instructionOnline',
        defaultMessage: 'I want to set up my Direct Debit instruction online'
    },
    nameConfirmation: {
        id: 'quoteandbind.ngh.views.directDebit.nameConfirmation',
        defaultMessage: 'The account to be debited is in my name'
    },
    personalAccountConfirmation: {
        id: 'quoteandbind.ngh.views.directDebit.personalAccountConfirmation',
        defaultMessage: 'The account to be debited is a personal account'
    },
    authorisedPerson: {
        id: 'quoteandbind.ngh.views.directDebit.authorisedPerson',
        defaultMessage: 'I am the only person required to authorise Direct Debits from the account'
    },
    pleaseNote: {
        id: 'quoteandbind.ngh.views.directDebit.pleaseNote',
        defaultMessage: 'Please note: '
    },
    instructions: {
        id: 'quoteandbind.ngh.views.directDebit.instructions',
        defaultMessage: 'Direct Debits will appear on your bank statement as NFU Mutual. You can cancel your Direct Debit at any time. A copy of the safeguards under the Direct Debit Guarantee will be sent to you along with the letter confirming the Direct Debit mandate'
    },
    ddAutomaticRenewalOptionTitle: {
        id: 'quoteandbind.ngh.views.directDebit.ddAutomaticRenewalOptionTitle',
        defaultMessage: 'Would you like your policy to renew automatically?'
    },
    ddAutomaticRenewalOptionPolicyRenewalText: {
        id: 'quoteandbind.ngh.views.directDebit.ddAutomaticRenewalOptionPolicyRenewalText',
        defaultMessage: 'We’ll always write to you when your policy is due for renewal.'
    },
    ddAutomaticRenewalOptionPolicyRenewYes: {
        id: 'quoteandbind.ngh.views.directDebit.ddAutomaticRenewalOptionPolicyRenewYes',
        defaultMessage: 'If you choose '
    },
    ddAutomaticRenewalOptionYesBold: {
        id: 'quoteandbind.ngh.views.directDebit.ddAutomaticRenewalOptionYesBold',
        defaultMessage: 'Yes, '
    },
    ddAutomaticRenewalOptionYes: {
        id: 'quoteandbind.ngh.views.directDebit.ddAutomaticRenewalOptionYes',
        defaultMessage: 'your policy will renew automatically and we’ll continue to take Direct Debit payments unless we hear from you, avoiding a break in your cover.'
    },
    ddAutomaticRenewalOptionPolicyRenewNo: {
        id: 'quoteandbind.ngh.views.directDebit.ddAutomaticRenewalOptionPolicyRenewNo',
        defaultMessage: 'If you choose '
    },
    ddAutomaticRenewalOptionNoBold: {
        id: 'quoteandbind.ngh.views.directDebit.ddAutomaticRenewalOptionNoBold',
        defaultMessage: 'No, '
    },
    ddAutomaticRenewalOptionNo: {
        id: 'quoteandbind.ngh.views.directDebit.ddAutomaticRenewalOptionNo',
        defaultMessage: 'your policy will not renew automatically and you will need  to contact us by your renewal date to continue your cover.'
    },
    renewYesInfoContent: {
        id: 'quoteandbind.ngh.views.directDebit.renewYesInfoContent',
        defaultMessage: 'You can change your automatic renewal preference at any time by phoning, emailing or writing to us.'
    },
    renewNoInfoContent: {
        id: 'quoteandbind.ngh.views.directDebit.renewNoInfoContent',
        defaultMessage: 'You can change your automatic renewal preference at any time by phoning, emailing or writing to us.'
    },
    directDebitSetupMainContainer: {
        id: 'quoteandbind.ngh.views.directdebit.setupDirectDebitTitle',
        defaultMessage: 'Set up your Direct Debit'
    },
    directDebitPageSetupDDFormAccountName: {
        id: 'quoteandbind.ngh.views.directDebit.AccountName',
        defaultMessage: 'Account holder Name'
    },
    directDebitPageSetupDDFormSortCode: {
        id: 'quoteandbind.ngh.views.directDebit.SortCode',
        defaultMessage: 'Sort code'
    },
    directDebitPageSetupDDFormAccountNumber: {
        id: 'quoteandbind.ngh.views.directDebit.AccountNumber',
        defaultMessage: 'Account number'
    },
    emailConfirmationContainer: {
        id: 'quoteandbind.ngh.views.directdebit.emailFieldTitle',
        defaultMessage: 'Confirm your email address'
    },
    directDebitPageSetupDDContainerEmailDescription: {
        id: 'quoteandbind.ngh.views.directdebit.emailFieldDescription',
        defaultMessage: 'Please check that your email address is correct.  You can change it below.'
    },
    autoRenewalContainer: {
        id: 'quoteandbind.ngh.views.directdebit.autoRenewalContainer',
        defaultMessage: 'Your renewal'
    },
    paymentSchedule: {
        id: 'quoteandbind.ngh.views.directdebit.paymentSchedule',
        defaultMessage: 'Payment schedule'
    },
    accountHolderNameTooltip: {
        id: 'quoteandbind.ngh.views.directdebit.accountHolderNameTooltip',
        defaultMessage: 'Please type the name  exactly as it appears on your account'
    },
    sortCodeTooltip: {
        id: 'quoteandbind.ngh.views.directdebit.sortCodeTooltip',
        defaultMessage: 'Please type the six digit sort code excluding any dashes'
    },
    accountNumberTooltip: {
        id: 'quoteandbind.ngh.views.directdebit.accountNumberTooltip',
        defaultMessage: 'Please type the eight digit account number as it appears on your account'
    },
    paymentScheduleHeaderTexColumn: {
        id: 'quoteandbind.ngh.views.directdebit.paymentScheduleHeaderTexColumn',
        defaultMessage: 'Payment date'
    },
    cancelTransaction: {
        id: 'quoteandbind.ngh.views.directdebit.cancelTransaction',
        defaultMessage: 'Cancel transaction',
    },
    sortCodevalidation: {
        id: 'quoteandbind.ngh.views.directdebit.sortCodevalidation',
        defaultMessage: 'Must be a 6 digit number',
    },
    acctValidation: {
        id: 'quoteandbind.ngh.views.directdebit.acctValidation',
        defaultMessage: 'Must be a 7 or 8 digit number',
    },
    requiredField: {
        id: 'quoteandbind.ngh.views.directdebit.requiredField',
        defaultMessage: 'This is a required field',
    }
});

import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import messages from './ProtectingYourValuables.messages';
import metadata from './ProtectingYourValuables.metadata.json5';
import styles from './ProtectingYourValuables.scss';
import InfoPanel from '../../../components/InfoPanel/InfoPanel';

function ProtectingYourValuables(props) {
    const translator = useContext(TranslatorContext);
    const {
        jobVM,
        value: securityQuestionsVM,
        onValueChange,
        path,
        isDisabled
    } = props;
    const conditionCode = Object.freeze({
        ALARM_SECURITY: 'HOMAlarmSecurityInsuranceCond',
        MINIMUM_SECURITY: 'HOMMinimumSecurityInsuranceCond',
        SAFE: 'HOMSafeRequirementCond'
    });
    const CONDITION_UPPER = 'Condition';
    const CONDITION_LOWER = 'condition';
    const WORDING = 'Wording';
    const [alarmSecurityCondition, setAlarmSecurityCondition] = useState(undefined);
    const [minimumSecurityCondition, setMinimumSecurityCondition] = useState(undefined);
    const [safeCondition, setSafeCondition] = useState(undefined);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const getMinSecurityExtendedData = () => {
        return (
            <p>
                { translator(messages.weWillAllowFrom) }
                <span className="xDays">{ translator(messages.xDays, { days: 28 }) }</span>
                { translator(messages.fromTheDateThat) }
            </p>
        );
    };

    const getAlarmSecurityExtendedData = () => {
        return (
            <p>
                { translator(messages.weWillAllowFrom) }
                <span className="xDays">{ translator(messages.xDays, { days: 28 }) }</span>
                { translator(messages.fromTheDateThatAlarm) }
            </p>
        );
    };

    function formatTextToHTML(text) {
        return text.replace(/\n\n/g, '<br><br>').replace(/\n/g, '<br>').replace(/££/g, '£');
    }

    function formatName(name) {
        if (name.includes(CONDITION_UPPER)) {
            return name.replace(CONDITION_UPPER, CONDITION_LOWER);
        }
        return name;
    }

    const processConditions = useCallback(() => {
        if (!jobVM.value.lobData.homeLine.lineCoverages.conditions
            || jobVM.value.lobData.homeLine.lineCoverages.conditions
                .length === 0
        ) {
            return [];
        }

        return jobVM.value.lobData.homeLine.lineCoverages.conditions
            .filter((cond) => cond.selected && [conditionCode.ALARM_SECURITY,
                conditionCode.MINIMUM_SECURITY,
                conditionCode.SAFE].includes(cond.codeIdentifier_NFUM)).forEach((cond) => {
                const wordingTerm = cond.terms.find(
                    (term) => term.codeIdentifier_NFUM.includes(WORDING)
                );
                const newCond = {
                    name: formatName(cond.name),
                    text: <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: formatTextToHTML(wordingTerm.chosenTerm)
                        }}
                    />
                };

                switch (cond.codeIdentifier_NFUM) {
                    case conditionCode.ALARM_SECURITY:
                        setAlarmSecurityCondition(newCond);
                        break;
                    case conditionCode.MINIMUM_SECURITY:
                        setMinimumSecurityCondition(newCond);
                        break;
                    case conditionCode.SAFE:
                        setSafeCondition(newCond);
                        break;
                    default:
                        break;
                }
            });
    }, [jobVM, conditionCode]);

    useEffect(() => {
        processConditions();
    }, [jobVM, processConditions]);

    const overrideProps = {
        minSecuritySection: {
            visible: minimumSecurityCondition !== undefined
        },
        alarmSecuritySection: {
            visible: alarmSecurityCondition !== undefined
        },
        safeConditionSection: {
            visible: safeCondition !== undefined
        },
        minSecurityInfoPanel: {
            isCollapsible: true,
            collapsibleButtonText: translator(messages.showMinimumSecurity),
            contentJsx: minimumSecurityCondition?.text,
            notCollapsibleButtonText: translator(messages.hideMinimumSecurity)
        },
        alarmSecurityInfoPanel: {
            isCollapsible: true,
            collapsibleButtonText: translator(messages.showMinimumSecurity),
            contentJsx: alarmSecurityCondition?.text,
            notCollapsibleButtonText: translator(messages.hideMinimumSecurity)
        },
        minSecurityExtendedInfoPanel: {
            visible: _.get(securityQuestionsVM, 'isMinSecurity.value') === false,
            isCollapsible: false,
            contentJsx: getMinSecurityExtendedData(),
            type: 'extended'
        },
        alarmSecurityExtendedInfoPanel: {
            visible: _.get(securityQuestionsVM, 'isAlarmSecurity.value') === false,
            isCollapsible: false,
            contentJsx: getAlarmSecurityExtendedData(),
            type: 'extended'
        },
        safeConditionExtendedInfoPanel: {
            visible: _.get(securityQuestionsVM, 'isSafeCondition.value') !== null,
            isCollapsible: false,
            contentJsx: safeCondition?.text,
            type: 'extended'
        },
        minSecurityYesOrNo: {
            disabled: isDisabled
        },
        alarmSecurityYesOrNo: {
            disabled: isDisabled
        },
        safeConditionYesOrNo: {
            disabled: isDisabled
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            infoPanel: InfoPanel
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={securityQuestionsVM}
            onValueChange={handleValueChange}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ProtectingYourValuables.propTypes = {
    value: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired
};

export default ProtectingYourValuables;

import { defineMessages } from 'react-intl';

export default defineMessages({
    noExtraCharge: {
        id: 'quoteandbind.ngh.views.payment.noExtraCharge',
        defaultMessage: 'We do not charge you any extra if you wish to pay in instalments'
    },
    supplementaryPaymentTitle: {
        id: 'quoteandbind.ngh.views.payment.supplementaryPaymentTitle',
        defaultMessage: 'Transaction 1 of 2: Initial payment'
    },
    supplementaryPaymentTopText: {
        id: 'quoteandbind.ngh.views.payment.supplementaryPaymentTopText',
        defaultMessage: 'As your policy begins within 10 working days, we need to take an initial card payment before setting up your Direct-Debit (which can take up to 10 working days).'
    },
    creditCardPaymentTitle: {
        id: 'quoteandbind.ngh.views.payment.creditCardPaymentTitle',
        defaultMessage: 'Total to pay now'
    },
    switchToMonthlyPremium: {
        id: 'quoteandbind.ngh.views.payment.switchToMonthlyPremium',
        defaultMessage: 'Switch to monthly premium'
    },
    switchToYearlyPremium: {
        id: 'quoteandbind.ngh.views.payment.switchToYearlyPremium',
        defaultMessage: 'Switch to yearly premium'
    },
    editQuoteLink: {
        id: 'quoteandbind.ngh.views.payment.editQuoteLink',
        defaultMessage: 'Edit quote'
    },
    cancelTransaction: {
        id: 'quoteandbind.ngh.views.payment.cancelTransaction',
        defaultMessage: 'Cancel transaction'
    },
    paymentGatewayIframe: {
        id: 'quoteandbind.ngh.views.payment.paymentGatewayIframe',
        defaultMessage: 'Payment gateway iframe​'
    },
});

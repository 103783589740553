import React, {
    useState, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelUtil } from 'gw-portals-viewmodel-js';
import generateSubComponents from './ScheduleItemTypes/ScheduleItemTypes';
import messages from './ScheduleItemsComponent.messages';

function ScheduleItemModalPopover(props) {
    const {
        propertyInfo,
        viewModelService,
        scheduleData,
        scheduleItem,
        labelPosition,
        id,
        isNew,
        isOpen,
        onResolve,
        onReject,
        bodyAutoFocus,
        phoneWide
    } = props;
    const translator = useTranslator();
    const localeService = ServiceManager.getService('locale-service');
    const [modalData, setModalData] = useState(scheduleData);
    const { onValidate } = useValidation(id);

    const modalOverrides = {
        '@field': {
            labelPosition,
            phoneWide,
            showOptional: true
        }
    };

    const handleChange = useCallback(
        (value, path) => {
            const clonedData = _.cloneDeep(modalData);
            const newPath = ViewModelUtil.getNonVMPath(path);
            _.set(clonedData, newPath, value);
            setModalData(clonedData);
        },
        [modalData]
    );

    const orderedPropertyInfo = useMemo(() => _.sortBy(propertyInfo, 'order'), [propertyInfo]);
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const subComponents = orderedPropertyInfo.flatMap((info) => generateSubComponents({
        info,
        modalData,
        viewModelService,
        translator,
        defaultCountryCode,
        scheduleItem,
        onValueChange: handleChange,
        onValidate,
        parentOverrides: modalOverrides,
        isNew
    }));

    const handleAdd = useCallback(() => {
        onResolve(modalData);
    }, [onResolve, modalData]);

    const modalOkLabel = useMemo(() => {
        return isNew ? messages.scheduleAdd : messages.save;
    }, [isNew]);

    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader
                title={scheduleItem.displayName}
            />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
                autoFocus={bodyAutoFocus}
            >
                {subComponents}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {messages.scheduleCancel}
                </Button>
                <Button onClick={handleAdd}>
                    {modalOkLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

ScheduleItemModalPopover.propTypes = {
    propertyInfo: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    scheduleData: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func,
    scheduleItem: PropTypes.shape({
        displayName: PropTypes.string
    }).isRequired,
    viewModelService: PropTypes.shape({}).isRequired,
    isNew: PropTypes.bool.isRequired,
    bodyAutoFocus: PropTypes.string,
    onReject: PropTypes.func
};
ScheduleItemModalPopover.defaultProps = {
    scheduleData: {},
    labelPosition: 'left',
    onResolve: undefined,
    phoneWide: {
        labelPosition: 'top'
    },
    bodyAutoFocus: '',
    onReject: undefined
};
export default ScheduleItemModalPopover;

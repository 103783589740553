import React, {
    useCallback, useState, useEffect, useContext, useRef
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { FineArtSummary, BespokeValuables } from 'gw-capability-quoteandbind-common-react';

import {
    useErrorHandler,
    QB_STEPS,
    QUOTE_RETRIEVAL_STEPS,
    AppContext
} from 'nfum-portals-utils-react';
import { useValidation } from 'gw-portals-validation-react';
import { NfumAccordionHeader, NfumConfirmModal } from 'nfum-components-platform-react';
import { useHistory } from 'react-router-dom';
import useCleanPayload from '../../hooks/useCleanPayload';
import usePropertyTitle from '../../hooks/usePropertyTitle';
import AnimalSummary from './AnimalSummary/AnimalSummary';
import CaravanSummary from './CaravanSummary/CaravanSummary';
import TravelSummary from './TravelSummary/TravelSummary';
import PersonalAccidentSummary from './PersonalAccidentSummary/PersonalAccidentSummary';
import BuildingsSummary from './BuildingsSummary/BuildingsSummary';
import ContentsSummary from './ContentsSummary/ContentsSummary';
import AdditionalCoversSummary from './AdditionalCoversSummary/AdditionalCoversSummary';
import PolicyDetailsSummary from './PolicyDetailsSummary/PolicyDetailsSummary';
import ValuablesSummary from './ValuablesSummary/ValuablesSummary';
import BicyclesSummary from './BicyclesSummary/BicyclesSummary';
import CostBreakdownBox from './CostBreakdownBox/CostBreakdownBox';
import CallOrContactInfo from '../../components/CallOrContactInfo/CallOrContactInfo';
import useLobs from '../../hooks/useLobs';
import DocumentsLinksComponent from '../../components/DocumentsLinks/DocumentsLinksComponent';
import styles from './PolicySummaryPage.module.scss';
import metadata from './PolicySummaryPage.metadata.json5';
import commonMessages from '../../NGHWizard.messages';
import messages from './PolicySummaryPage.messages';
import useNextPaymentPath from '../../hooks/useNextPaymentPath';
import useCallRetry from '../../hooks/useCallRetry';
import useTagManager from '../../hooks/useTagManager';
import useCmsDocumentsRetriever from '../../hooks/useCmsDocumentsRetriever';
import NGHContext from '../../NGHContext';
import SpecialConditions from './SpecialConditions/SpecialConditions';

function PolicySummaryPage(props) { /* NOSONAR: pure declarative usage */
    const {
        wizardData: submissionVM, updateWizardData, steps, jumpTo, changeNextSteps, currentStepIndex
    } = props;
    const translator = useContext(TranslatorContext);
    const { showModal, showAlert } = useModal();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { PolicyService } = useDependencies('PolicyService');
    const { authHeader } = useAuthentication();
    const { handleError } = useErrorHandler();
    const { selectNextPaymentPath } = useNextPaymentPath(submissionVM);
    const { isLifestyleJourney } = useLobs(submissionVM);
    const { getPropertyTitle } = usePropertyTitle();
    const history = useHistory();
    const {
        isCaravanLine,
        isTravelLine,
        isAnimalLine,
        isPersonalAccidentLine
    } = useLobs(submissionVM);
    const {
        onValidate,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('PolicySummaryPage');
    const {
        isQuoteEmailSent,
        setIsQuoteEmailSent,
        costInLastEmailSent,
        setCostInLastEmailSent
    } = useContext(NGHContext);
    const { cleanNotUpdatedCoverages, cleanCostInfos } = useCleanPayload();
    const documentsSectionRef = useRef();
    const scrollToTopSection = useRef();
    const scrollToPolicyDetailsCard = useRef();
    const scrollToBuildingCardSection = useRef();
    const scrollToContentsCardSection = useRef();
    const scrollToValuablesSection = useRef();
    const scrollToBespokeValuablesSection = useRef();
    const scrollToFineArtsAndCollectionsSection = useRef();
    const scrollToBicycleSection = useRef();
    const scrollToAdditionalInsuranceSection = useRef();
    const scrollToCaravanSection = useRef();
    const scrollToDogCatSection = useRef();
    const scrollToPersonalAccidentSection = useRef();
    const scrollToTravelCardSection = useRef();
    const isYearlyPayment = _.get(submissionVM, 'baseData.value.paymentMethod_NFUM') === 'cash';
    const homeLineCoverages = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.coverages');
    const buildingsCov = homeLineCoverages?.find((cov) => cov.publicID === 'HOMBuildingsCov');
    const isBuildingsCov = buildingsCov?.selected;
    const contentsCov = homeLineCoverages?.find((cov) => cov.publicID === 'HOMContentsCov');
    const isContentsCov = contentsCov?.selected;
    const bespokeValuableCov = homeLineCoverages?.find((cov) => cov.publicID === 'HOMValuablesCov');
    const isBespokeValuableCov = bespokeValuableCov?.selected;
    const fineArtsCov = homeLineCoverages?.find((cov) => cov.publicID === 'HOMFineArtCollectionsCov');
    const isFineArtsCov = fineArtsCov?.selected;
    const [isConditionsExclusionsAvailable, setIsConditionsExclusionsAvailable] = useState(false);
    const DOCUMENT_TIMEOUT_DURATION_SECONDS = 180;
    const [addressValidationInd, setAddressValidationInd] = useState(null);
    const [disableNext, setDisableNext] = useState(false);
    const sourceOfBusiness = _.get(submissionVM.value, 'sourceOfBusiness_NFUM');
    const policyDetailsOffset = -110;
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushAccordionOpenInfo,
        pushFileDownloadInfo,
        pushLinkClickInfo,
        pushRelativeLinkClickInfo
    } = useTagManager();
    const { isQuoteRetrieval, isRetrievedAsQuoted } = useContext(AppContext);
    const { isPaymentSucceed } = useContext(NGHContext);
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const previousStepAvailable = currentStepIndex !== 0 && !isPaymentSucceed;
    const cardsIds = Object.freeze({
        POLICY_DETAILS_CARD: 'policyDetailsCard',
        ADDITIONAL_COVERS_CARD: 'additionalCoversCard',
        BUILDINGS_CARD: 'buildingsInsuranceCard',
        CONTENTS_CARD: 'contentsInsuranceCard',
        CARAVAN_CARD: 'caravanCard',
        CAT_DOG_CARD: 'dogAndCatCard',
        TRAVEL_CARD: 'travelCard',
        PERSONAL_ACCIDENT_CARD: 'personalAccidentCard',
        VALUABLES_CARD: 'valuablesCard',
        BESPOKE_VALUABLES_CARD: 'bespokeValuablesCard',
        FINEARTS_COLLECTIONS_CARD: 'fineartAndCollectionCard',
        BICYCLES_CARD: 'bicyclesCard'
    });
    const [isCmsDocumentsLoading, setIsCmsDocumentsLoading] = useState(true);
    const [cmsDocuments, setCmsDocuments] = useState([]);
    const { getDocumentsToDisplay } = useCmsDocumentsRetriever();

    useEffect(() => {
        const documentErrorInfoChannel = new BroadcastChannel('document_error');

        documentErrorInfoChannel.onmessage = () => {
            history.push('/service-unavailable');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #region GTM EVENTS
    useEffect(() => {
        const { isSkipping } = props;
        if (isSkipping) {
            initialValidation().then((skip) => {
                if (!skip) {
                    pushFormStepInfo(submissionVM, isQuoteRetrieval
                        ? QUOTE_RETRIEVAL_STEPS.QUOTE_SUMMARY
                        : QB_STEPS.POLICY_SUMMARY);
                }
            });
        } else {
            pushFormStepInfo(submissionVM, isQuoteRetrieval
                ? QUOTE_RETRIEVAL_STEPS.QUOTE_SUMMARY
                : QB_STEPS.POLICY_SUMMARY);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAdditionalInsuranceAccordion = () => {
        if (scrollToBicycleSection?.current) {
            scrollToBicycleSection?.current?.scrollIntoView();
        } else if (scrollToFineArtsAndCollectionsSection?.current) {
            scrollToFineArtsAndCollectionsSection?.current?.scrollIntoView();
        } else if (scrollToBespokeValuablesSection?.current) {
            scrollToBespokeValuablesSection?.current?.scrollIntoView();
        } else if (scrollToContentsCardSection?.current) {
            scrollToContentsCardSection?.current?.scrollIntoView();
        } else if (scrollToBuildingCardSection?.current) {
            scrollToBuildingCardSection?.current?.scrollIntoView();
        } else {
            scrollToPolicyDetailsCard?.current?.scrollIntoView();
        }
    };

    const handleBespokeValuablesAccordion = () => {
        if (scrollToContentsCardSection?.current) {
            scrollToContentsCardSection?.current?.scrollIntoView();
        } else if (scrollToBuildingCardSection?.current) {
            scrollToBuildingCardSection?.current?.scrollIntoView();
        } else {
            scrollToPolicyDetailsCard?.current?.scrollIntoView();
        }
    };

    const handleFineArtsAccordion = () => {
        if (scrollToBespokeValuablesSection?.current) {
            scrollToBespokeValuablesSection?.current?.scrollIntoView();
        } else if (scrollToContentsCardSection?.current) {
            scrollToContentsCardSection?.current?.scrollIntoView();
        } else if (scrollToBuildingCardSection?.current) {
            scrollToBuildingCardSection?.current?.scrollIntoView();
        } else {
            scrollToPolicyDetailsCard?.current?.scrollIntoView();
        }
    };

    const onUpdateOpenAccordion = useCallback((accordionCardsIds) => {
        const openCardId = accordionCardsIds && accordionCardsIds.length > 0
            ? accordionCardsIds[0] : null;
        switch (openCardId) {
            case cardsIds.POLICY_DETAILS_CARD:
                pushAccordionOpenInfo(translator(messages.policyDetails));
                scrollToTopSection?.current?.scrollIntoView();
                window.scrollBy(0, policyDetailsOffset);
                break;
            case cardsIds.ADDITIONAL_COVERS_CARD:
                pushAccordionOpenInfo(translator(messages.additionalCovers));
                handleAdditionalInsuranceAccordion();
                break;
            case cardsIds.BUILDINGS_CARD:
                pushAccordionOpenInfo(translator(messages.buildings));
                scrollToTopSection?.current?.scrollIntoView();
                break;
            case cardsIds.CONTENTS_CARD:
                pushAccordionOpenInfo(translator(messages.contents));
                scrollToBuildingCardSection?.current?.scrollIntoView();
                break;
            case cardsIds.CARAVAN_CARD:
                pushAccordionOpenInfo(translator(messages.caravan));
                scrollToCaravanSection?.current?.scrollIntoView();
                break;
            case cardsIds.CAT_DOG_CARD:
                pushAccordionOpenInfo(translator(messages.dogAndCat));
                scrollToDogCatSection?.current?.scrollIntoView();
                break;
            case cardsIds.TRAVEL_CARD:
                pushAccordionOpenInfo(translator(messages.travel));
                scrollToTravelCardSection?.current?.scrollIntoView();
                break;
            case cardsIds.PERSONAL_ACCIDENT_CARD:
                pushAccordionOpenInfo(translator(messages.personalAccident));
                scrollToPersonalAccidentSection?.current?.scrollIntoView();
                break;
            case cardsIds.VALUABLES_CARD:
                pushAccordionOpenInfo(translator(messages.valuables));
                scrollToContentsCardSection?.current?.scrollIntoView();
                break;
            case cardsIds.BESPOKE_VALUABLES_CARD:
                pushAccordionOpenInfo(translator(messages.valuables));
                handleBespokeValuablesAccordion();
                break;
            case cardsIds.FINEARTS_COLLECTIONS_CARD:
                pushAccordionOpenInfo(translator(messages.fineArtsCollections));
                handleFineArtsAccordion();
                break;
            case cardsIds.BICYCLES_CARD:
                pushAccordionOpenInfo(translator(messages.bicycles));
                scrollToContentsCardSection?.current?.scrollIntoView();
                break;
            default:
                // do nothing
        }
    }, [cardsIds,
        pushAccordionOpenInfo,
        translator,
        policyDetailsOffset]);
    // #endregion GTM EVENTS

    useEffect(() => {
        const lobData = _.get(submissionVM.value, 'lobData');
        const res = Object.entries(lobData)
            .reduce((initValue, [, value]) => initValue
                || value?.isConditionsExclusionsAvailable, false);
        setIsConditionsExclusionsAvailable(res);
    }, [submissionVM]);

    useEffect(() => {
        const fetchCmsDocuments = async () => {
            let docs = [];
            try {
                docs = await getDocumentsToDisplay(submissionVM);
            } catch (err) {
                history.push(`/service-unavailable?jobid=${submissionVM.quoteID.value}`);
            }
            if (docs.length === 0) {
                history.push(`/service-unavailable?jobid=${submissionVM.quoteID.value}`);
            }
            setIsCmsDocumentsLoading(false);
            setCmsDocuments(docs);
            return docs;
        };

        if (!isRetrievedAsQuoted) {
            setIsCmsDocumentsLoading(true);
            fetchCmsDocuments();
        } else {
            setIsCmsDocumentsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDocuments = useCallback(async () => {
        const docs = await PolicyService.getDocuments(
            [submissionVM.value.quoteID]
        );
        return docs;
    }, [PolicyService, submissionVM]);

    const {
        loading: isDocumentsLoading,
        error: docError,
        data: documents
    } = useCallRetry(
        fetchDocuments,
        {
            retryTimes: 10,
            retryDelayInSeconds: 10,
            initialIsLoading: true,
            timeoutDurationSeconds: DOCUMENT_TIMEOUT_DURATION_SECONDS
        },
        [],
        isRetrievedAsQuoted === true
    );

    const skipWhenRetrieved = useCallback(() => {
        return _.get(submissionVM.value, 'baseData.periodStatus') === 'Bound';
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(skipWhenRetrieved);
    }, [skipWhenRetrieved, registerInitialComponentValidation]);

    useEffect(() => {
        const paymentOption = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM');
        changeNextSteps(selectNextPaymentPath(paymentOption));
        _.set(submissionVM.value, 'bindData.paymentDetails', undefined);
        updateWizardData(submissionVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePaymentPeriodChange = () => {
        const typeOfPayment = _.get(submissionVM, 'baseData.value.paymentMethod_NFUM');
        const newTypeOfPayment = typeOfPayment === 'directdebit' ? 'cash' : 'directdebit';
        _.set(submissionVM, 'baseData.paymentMethod_NFUM', newTypeOfPayment);
        changeNextSteps(selectNextPaymentPath(newTypeOfPayment));
        updateWizardData(submissionVM);
    };

    const preparePolicyDocumentMessage = useCallback(() => {
        if (submissionVM.baseData.accountHolder.customerCareDocumentFormate_NFUM.value) {
            const documentFormatValue = submissionVM.baseData.accountHolder
                .customerCareDocumentFormate_NFUM.value.code;
            const documentFormatType = translator(messages[documentFormatValue]);
            return translator(
                messages.yourPolicyDocumentsMessageInAlternateFormat,
                { PreferredFormat: documentFormatType }
            );
        }
        return translator(messages.yourPolicyDocumentsMessage);
    }, [translator, submissionVM]);

    const handleChangePreferredMethod = useCallback(
        async (value, path) => {
            const { type } = await showModal(
                <NfumConfirmModal variant="variant5" />
            );
            if (type === 'CANCEL') {
                _.set(submissionVM, path, false);
                updateWizardData(submissionVM);
                return;
            }
            _.set(submissionVM, path, true);
            updateWizardData(submissionVM);
        },
        [
            submissionVM,
            updateWizardData,
            showModal
        ]
    );

    const handleAddressValidationChange = useCallback((isConfirmed) => {
        if (isConfirmed) {
            setDisableNext(true);
            setAddressValidationInd(true);
        } else {
            setDisableNext(false);
            setAddressValidationInd(false);
        }
    }, [setAddressValidationInd]);

    const sendEmail = useCallback(async () => {
        const currentTotalCost = _.get(
            submissionVM.value,
            'quoteData.offeredQuotes[0].premium.total.amount'
        );
        const isTheSameCostInLastEmail = currentTotalCost === costInLastEmailSent;

        if (isQuoteEmailSent && isTheSameCostInLastEmail) {
            showAlert({
                title: messages.emailQuoteSentTitle,
                message: messages.emailQuoteAlreadySentMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            return;
        }
        try {
            await LoadSaveService
                .createQuoteDocs([submissionVM.quoteID.value, false], { authHeader });
            setIsQuoteEmailSent(true);
            setCostInLastEmailSent(currentTotalCost);
            showAlert({
                title: messages.emailQuoteSentTitle,
                message: messages.emailQuoteSentMessage,
            }).catch(_.noop);
        } catch (e) {
            showAlert({
                title: messages.error,
                message: messages.cantSendQuoteDetailsAtThisTime,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }
    }, [
        submissionVM,
        setIsQuoteEmailSent,
        isQuoteEmailSent,
        LoadSaveService,
        authHeader,
        showAlert,
        costInLastEmailSent,
        setCostInLastEmailSent
    ]);

    const handlePageNavigation = useCallback((url, linkText) => {
        pushRelativeLinkClickInfo(translator(linkText), url);
        const indexOfPolicyDetails = _.findIndex(
            steps,
            ({ path }) => path === url
        );
        jumpTo(indexOfPolicyDetails);
    }, [jumpTo, steps, pushRelativeLinkClickInfo, translator]);

    const handleNext = useCallback(async () => {
        try {
            cleanNotUpdatedCoverages(submissionVM);
            cleanCostInfos(submissionVM);
            submissionVM.value = await LoadSaveService.updateQuotedSubmission(
                submissionVM.value,
                authHeader
            );
        } catch (error) {
            pushFormStepErrorInfo(submissionVM, isQuoteRetrieval
                ? QUOTE_RETRIEVAL_STEPS.QUOTE_SUMMARY
                : QB_STEPS.POLICY_SUMMARY, error);
            handleError(error, submissionVM.value.quoteID);
            return false;
        }
        return submissionVM;
    }, [
        LoadSaveService,
        authHeader,
        submissionVM,
        handleError,
        isQuoteRetrieval,
        pushFormStepErrorInfo,
        cleanNotUpdatedCoverages,
        cleanCostInfos
    ]);

    // eslint-disable-next-line max-len
    const renderAccordionHeader = (isOpen, title, openMessage, scrollToDocumentsSection) => { /* NOSONAR: pure declarative usage */
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                title={title}
                openMessage={openMessage}
                showCloseMessage
                onClick={scrollToDocumentsSection}
            />
        );
    };

    const scrollToDocumentsSection = useCallback(() => {
        documentsSectionRef?.current?.scrollIntoView();
    }, []);

    const scrollToTopSectionSelection = useCallback(() => {
        scrollToTopSection?.current?.scrollIntoView();
    }, []);

    const onFindAgencyClicked = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/agent-offices/';
        pushLinkClickInfo(translator(messages.customMessageDocumentation1), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const homeScheduleItems = _.get(submissionVM.value, 'lobData.homeLine.lineCoverages.schedules[0].scheduleItems');
    const fineArtsItems = _.get(submissionVM.value, 'lobData.homeLine.lineCoverages.fineArts') || [];
    const valuables = homeScheduleItems
        ?.filter((item) => !_.isEmpty(item.itemData.ArticleGeographicLimit)) || [];
    const bicycles = homeScheduleItems
        ?.filter((item) => _.isEmpty(item.itemData.ArticleGeographicLimit)) || [];

    const renderValuableHeader = (isOpen) => {
        return renderAccordionHeader(isOpen, messages.valuables, messages.reviewCover);
    };

    const overrideProps = {
        policyDetailsList: {
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        valuablesHomePropertyTitle: {
            content: getPropertyTitle(_.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty')),
            visible: homeScheduleItems?.length < 21
        },
        [cardsIds.POLICY_DETAILS_CARD]: {
            visible: true,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.policyDetails,
                    messages.reviewDetails
                );
            }
        },
        [cardsIds.ADDITIONAL_COVERS_CARD]: {
            visible: !!isBuildingsCov || !!isContentsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.additionalCovers,
                    messages.reviewCover,
                    scrollToTopSectionSelection
                );
            }
        },
        [cardsIds.BUILDINGS_CARD]: {
            visible: !!isBuildingsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.buildings,
                    messages.reviewCover,
                    scrollToTopSectionSelection
                );
            }
        },
        [cardsIds.CONTENTS_CARD]: {
            visible: !!isContentsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.contents,
                    messages.reviewCover,
                    scrollToTopSectionSelection
                );
            }
        },
        [cardsIds.CARAVAN_CARD]: {
            visible: isCaravanLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.caravan,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.CAT_DOG_CARD]: {
            visible: isAnimalLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.dogAndCat,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.TRAVEL_CARD]: {
            visible: isTravelLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.travel,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.PERSONAL_ACCIDENT_CARD]: {
            visible: isPersonalAccidentLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.personalAccident,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.VALUABLES_CARD]: {
            visible: valuables.length > 0,
            renderHeader: renderValuableHeader
        },
        [cardsIds.BESPOKE_VALUABLES_CARD]: {
            visible: true,
            renderHeader: renderValuableHeader
        },
        [cardsIds.FINEARTS_COLLECTIONS_CARD]: {
            visible: true,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.fineArtsCollections,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.BICYCLES_CARD]: {
            visible: bicycles.length > 0,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.bicycles,
                    messages.reviewCover
                );
            }
        },
        costBreakdownBox: {
            submissionVM, previousStepAvailable, steps, jumpTo, changeNextSteps, currentStepIndex
        },
        policyDetailsSummary: {
            submissionVM
        },
        buildingsSummary: {
            homeLine: _.get(submissionVM, 'lobData.value.homeLine'),
            submissionVM
        },
        contentsSummary: {
            homeLine: _.get(submissionVM, 'lobData.value.homeLine'),
            submissionVM
        },
        additionalCoversSummary: {
            submissionVM
        },
        optedOutOfAutoRenewal: {
            value: _.get(submissionVM, 'baseData.value.automaticRenewalOoption_NFUM') ? translator(messages.yes) : translator(messages.no)
        },
        customerCareDocumentFormat: {
            value: _.get(submissionVM, 'baseData.value.accountHolder.alternateFormat_NFUM')
                ? translator({
                    id: `typekey.CustCareDocFormatType_NFUM.${_.get(submissionVM, 'baseData.value.accountHolder.customerCareDocumentFormate_NFUM')}`,
                    defaultMessage: _.get(submissionVM, 'baseData.value.accountHolder.customerCareDocumentFormate_NFUM')
                })
                : translator(messages.noPreference)
        },
        caravanSummary: {
            value: _.get(submissionVM, 'lobData.value.caravanLine.coverables.caravans')
        },
        travelSummary: {
            value: _.get(submissionVM, 'lobData.value.travelLine.coverables.travellers'),
            submissionVM
        },
        animalSummary: {
            value: _.get(submissionVM, 'lobData.value.animalLine.coverables.animals')
        },
        personalAccidentSummary: {
            value: _.get(submissionVM, 'lobData.value.personalAccidentLine.coverables.individuals')
        },
        preferredMethodCheckbox: {
            onValueChange: handleChangePreferredMethod,
        },
        documentsList: {
            documents: (documents || []).concat(cmsDocuments),
            pushFileDownloadInfo,
            isCmsDocument: !isRetrievedAsQuoted
        },
        editPolicyDetailsLink: {
            visible: previousStepAvailable,
            onClick: () => handlePageNavigation('/your-details', messages.editPolicyDetails)
        },
        editContentsLink: {
            visible: previousStepAvailable,
            onClick: () => handlePageNavigation('/property-construction', messages.editContents)
        },
        editCoveragesLink: {
            visible: previousStepAvailable,
            onClick: () => handlePageNavigation('/property-construction', messages.editBuildingsInsurance)
        },
        editValuablesLink: {
            visible: previousStepAvailable,
            onClick: () => handlePageNavigation('/valuables', messages.editValuables)
        },
        editAdditionalCoversLink: {
            visible: previousStepAvailable,
            onClick: () => handlePageNavigation('/quote', messages.editAdditionalCoverages)
        },
        editQuoteLink: {
            visible: previousStepAvailable,
            onClick: () => handlePageNavigation('/quote', messages.editQuoteDetails),
            disable: submissionVM.value.isBespokeHomeInsurance_NFUM,
        },
        yourPolicyDocumentsMessage: {
            content: preparePolicyDocumentMessage(),
        },
        switchTypeOfPremium: {
            content: isYearlyPayment
                ? translator(messages.switchToMonthlyPremium)
                : translator(messages.switchToYearlyPremium),
            onClick: handlePaymentPeriodChange,
            visible: !isPaymentSucceed
        },
        documentsSectionAnchor: {
            ref: documentsSectionRef
        },
        editPremiumLinksContainer: {
            ref: scrollToTopSection
        },
        policyDetailsCardContainer: {
            ref: scrollToTopSection
        },
        buildingsInsuranceCardContainer: {
            visible: !!isBuildingsCov,
            ref: scrollToBuildingCardSection
        },
        contentsInsuranceCardContainer: {
            visible: !!isContentsCov,
            ref: scrollToContentsCardSection
        },
        valuablesCardContainer: {
            visible: valuables.length > 0,
            ref: scrollToValuablesSection
        },
        bespokeValuablesCardContainer: {
            visible: submissionVM.value.isBespokeHomeInsurance_NFUM
                && isBespokeValuableCov === true,
            ref: scrollToBespokeValuablesSection
        },
        valuablesContainer: {
            visible: valuables.length > 0
        },
        fineartAndCollectionCardContainer: {
            visible: submissionVM.value.isBespokeHomeInsurance_NFUM
                && isFineArtsCov === true,
            ref: scrollToFineArtsAndCollectionsSection
        },
        bicyclesCardContainer: {
            visible: bicycles.length > 0 && !submissionVM.value.isBespokeHomeInsurance_NFUM,
            ref: scrollToBicycleSection
        },
        additionalCoversCardContainer: {
            visible: !!isBuildingsCov || !!isContentsCov,
            ref: scrollToAdditionalInsuranceSection
        },
        caravanCardContainer: {
            visible: isCaravanLine,
            ref: scrollToCaravanSection
        },
        dogAndCatCardContainer: {
            visible: isAnimalLine,
            ref: scrollToDogCatSection
        },
        personalAccidentCardContainer: {
            visible: isPersonalAccidentLine,
            ref: scrollToPersonalAccidentSection
        },
        travelCardContainer: {
            visible: isTravelLine,
            ref: scrollToTravelCardSection
        },
        referralMessageContainer: {
            visible: addressValidationInd === false
        },
        referralMessage: {
            title: translator(messages.travel),
            description: translator(messages.travel),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        specialConditionsContainer: {
            visible: isRetrievedAsQuoted === true && isConditionsExclusionsAvailable === true
        },
        specialConditionsContainerForOnlineQuote: {
            visible: !isRetrievedAsQuoted,
            jobVM: submissionVM
        },
        policyScheduleLink: {
            onClick: () => scrollToDocumentsSection()
        },
        riskBillingAddValidationInd: {
            onValueChange: handleAddressValidationChange,
            value: addressValidationInd
        },
        addValidationReferralMessage: {
            paragraph1: translator(messages.addValidationReferalMessage1),
            paragraph2: translator(messages.addValidationReferalMessage2),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        loader: {
            visible: isDocumentsLoading || isCmsDocumentsLoading,
            loaded: !isDocumentsLoading && !isCmsDocumentsLoading
        },
        summaryComponent: {
            visible: !isDocumentsLoading && !isCmsDocumentsLoading
        },
        valuablesSummary: {
            visible: valuables.length > 0,
            scheduleItems: valuables,
            submissionVM,
            homeProperty: _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty')
        },
        bespokeValuablesSummary: {
            scheduleItems: valuables,
            submissionVM,
            homeProperty: _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty')
        },
        fineArtsCollectionsSummary: {
            fineArtsItems: fineArtsItems,
            submissionVM,
            homeProperty: _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty'),
            onClickSeeDocuments: () => documentsSectionRef?.current?.scrollIntoView(),
        },
        fineArtsCollectionsContainer: {
            visible: true,
        },
        bespokeValuables: {
            scheduleItems: homeScheduleItems,
            submissionVM,
            visible: homeScheduleItems?.length < 21
        },
        valuablesInfoContainer: {
            visible: homeScheduleItems?.length < 21
        },
        customMessageDiv: {
            visible: homeScheduleItems?.length > 20
        },
        bicyclesSummary: {
            scheduleItems: bicycles,
            homeProperty: _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty')
        },
        confirmBillingAddressMessage: {
            value: _.get(submissionVM.value, 'baseData.billingAddress_NFUM.displayName'),
            readOnly: true
        },
        buildingsInfoContainer: {
            visible: isQuoteRetrieval
        },
        buildingsInfoContainerForOnlineQuote: {
            visible: (!submissionVM.value.isBespokeHomeInsurance_NFUM &&
            (isQuoteRetrieval === false))
        },
        contentsInfoContainer: {
            visible: isQuoteRetrieval
        },
        contentsInfoContainerForOnlineQuote: {
            visible: (!submissionVM.value.isBespokeHomeInsurance_NFUM &&
                (isQuoteRetrieval === false))
        },
        additionalCoversInfoContainer: {
            visible: isQuoteRetrieval
        },
        additionalCoversInfoContainerForOnlineQuote: {
            visible: (!submissionVM.value.isBespokeHomeInsurance_NFUM &&
                (isQuoteRetrieval === false))
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentsMap: {
            callOrContactInfo: CallOrContactInfo,
            documentsLinks: DocumentsLinksComponent,
            caravanSummary: CaravanSummary,
            travelSummary: TravelSummary,
            animalSummary: AnimalSummary,
            personalAccidentSummary: PersonalAccidentSummary,
            buildingsSummary: BuildingsSummary,
            contentsSummary: ContentsSummary,
            additionalCoversSummary: AdditionalCoversSummary,
            policyDetailsSummary: PolicyDetailsSummary,
            bicyclesSummary: BicyclesSummary,
            costBreakdownBox: CostBreakdownBox,
            valuablesSummary: ValuablesSummary,
            fineArtsCollectionsSummary: FineArtSummary,
            bespokeValuables: BespokeValuables,
            specialConditions: SpecialConditions
        },
        resolveCallbackMap: {
            onScrollToDocumentsSection: scrollToDocumentsSection,
            onScrollToContactAgent: onFindAgencyClicked
        }
    };

    if (docError?.errorCode) {
        pushFormStepErrorInfo(submissionVM, isQuoteRetrieval
            ? QUOTE_RETRIEVAL_STEPS.QUOTE_SUMMARY
            : QB_STEPS.POLICY_SUMMARY, docError);
        handleError(docError, submissionVM.value.quoteID);
        return false;
    }

    return (
        <WizardPage
            skipWhen={initialValidation}
            onNext={handleNext}
            showCancel={false}
            nextLabel={commonMessages.purchase}
            showPrevious={false}
            showSaveForLater
            saveForLaterLabel={messages.saveForLater}
            showEmailQuote={!isDocumentsLoading && !isCmsDocumentsLoading && !isRetrievedAsQuoted}
            onEmailQuote={sendEmail}
            showNext={!isDocumentsLoading && !isCmsDocumentsLoading}
            disableNext={!disableNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                onValidationChange={onValidate}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentsMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PolicySummaryPage.propTypes = wizardProps;
export default PolicySummaryPage;

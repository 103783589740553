import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'quoteandbind.ngh.views.valuables.protectingYourValuables.title',
        defaultMessage: 'Protecting your valuables'
    },
    showMinimumSecurity: {
        id: 'quoteandbind.ngh.views.valuables.protectingYourValuables.showMinimumSecurity',
        defaultMessage: 'Show our minimum security conditions'
    },
    hideMinimumSecurity: {
        id: 'quoteandbind.ngh.views.valuables.protectingYourValuables.hideMinimumSecurity',
        defaultMessage: 'Hide our minimum security conditions'
    },
    weWillAllowFrom: {
        id: 'quoteandbind.ngh.views.valuables.protectingYourValuables.weWillAllowFrom',
        defaultMessage: 'We will allow for up to'
    },
    xDays: {
        id: 'quoteandbind.ngh.views.valuables.protectingYourValuables.xDays',
        defaultMessage: '{days} days'
    },
    fromTheDateThat: {
        id: 'quoteandbind.ngh.views.valuables.protectingYourValuables.fromTheDateThat',
        defaultMessage: 'from the date that the condition applies for the locks to be fitted. If the locks are not installed within this time, we won’t cover loss or damage caused by theft or attempted theft from the home'
    },
    fromTheDateThatAlarm: {
        id: 'quoteandbind.ngh.views.valuables.protectingYourValuables.fromTheDateThatAlarm',
        defaultMessage: 'from the date that the condition applies for the alarm to be installed. If the alarm is not installed within this time, we won’t cover loss or damage caused by theft or attempted theft from the home.'
    }
});

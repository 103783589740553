import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './AccessListItems.metadata.json5';
import styles from './AccessListItems.module.scss';

const StatusOptions = Object.freeze({
    IN_ELIGIBLE: 'ineligible',
    ELIGIBLE: 'eligible',
    ELIGIBLE_READY: 'eligibleready',
    INVITED: 'invited',
    IN_COMPLETE: 'incomplete',
    REGISTERED: 'registered',
    DE_REGISTERED: 'deregistered',
    UNKNOWN: 'unknown'
});

function AccessListItems({ status = StatusOptions.IN_COMPLETE }) {
    const isRegistered = StatusOptions.REGISTERED === status;
    const isEligible = StatusOptions.ELIGIBLE === status;
    const isEligibleReady = StatusOptions.ELIGIBLE_READY === status;
    const isInvitedOrincomplete = StatusOptions.INVITED === status
        || StatusOptions.IN_COMPLETE === status;

    const overrideProps = {
        onlineAccountActionitem1: {
            visible: isInvitedOrincomplete || isEligibleReady || isEligible,
        },
        onlineAccountActionitem2: {
            visible: isRegistered || isInvitedOrincomplete || isEligibleReady || isEligible,
        },
        onlineAccountActionitem3: {
            visible: isRegistered || isInvitedOrincomplete || isEligibleReady || isEligible,
        },
        onlineAccountActionitem4: {
            visible: isRegistered,
        },
        onlineAccountActionitem5: {
            visible: isInvitedOrincomplete || isEligibleReady || isEligible,
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default AccessListItems;

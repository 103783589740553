import { useState, useCallback } from 'react';

/**
 * Throws React error. In order to make React aware of the error thrown
 * outside of the rendering lifecycle
 * an error is triggered in a state update. This will make sure the errors
 * are intercepted by the closest error boundary.
 *
 * @memberof module:gw-portals-error-react
 * @function useErrors
 *
 * @example
 * const { throwReactError } = useErrors();
 * () => {
 *    ...
 * }).catch(throwReactError);
 *
 * @returns {Object} React error
 */
export default function useErrors() {
    const [, updateErrorState] = useState();

    const throwReactError = useCallback((err) => {
        updateErrorState(() => { throw err; });
    }, []);

    return { throwReactError };
}

import { defineMessages } from 'react-intl';

export default defineMessages({
    nfum: {
        id: 'footer.nfum',
        defaultMessage: '© NFU Mutual 2024.'
    },
    footerInfoContent: {
        id: 'footer.footerInfoContent',
        defaultMessage: 'The National Farmers Union Mutual Insurance Society Limited (No.111982). Registered in England. Registered office: Tiddington Road, Stratford upon Avon, Warwickshire CV37 7BJ. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. A member of the Association of British Insurers.'
    }
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    problemGeneratingQuote: {
        id: 'quoteandbind.directives.SystemDownMessageContent.problemGeneratingQuote',
        defaultMessage: 'We are experiencing a problem generating your quote at the moment.'
    },
    tryAgainLater: {
        id: 'quoteandbind.directives.SystemDownMessageContent.Please try again a little later',
        defaultMessage: 'We are aware of the issue and are working to solve it urgently. Please try again a little later.'
    },
    savedQuote: {
        id: 'quoteandbind.directives.SystemDownMessageContent.savedQuote',
        defaultMessage: 'Don\'t worry, your progress has been saved. You can resume your progress by calling our UK-based team, or arrange a call back for a time that suits you.'
    },
    phoneNumber: {
        id: 'quoteandbind.directives.SystemDownMessageContent.phoneNumber',
        defaultMessage: '0800 208 8835',
    },
    phoneTimings: {
        id: 'quoteandbind.directives.SystemDownMessageContent.phoneTimings',
        defaultMessage: '(Monday-Sat, 9am-5pm)',
    },
    sundayCallWindow: {
        id: 'quoteandbind.directives.SystemDownMessageContent.Sunday11',
        defaultMessage: 'Sunday: Closed',
    },
    callback: {
        id: 'quoteandbind.directives.SystemDownMessageContent.Arrange',
        defaultMessage: 'Arrange a call back',
    },
    findLocalAgency: {
        id: 'quoteandbind.directives.SystemDownMessageContent.findLocalAgency',
        defaultMessage: 'Find your local agency',
    },
    sendEmail: {
        id: 'quoteandbind.directives.SystemDownMessageContent.sendEmail',
        defaultMessage: 'Send us an email',
    },
});

import React, { useContext } from 'react';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import styles from './NfumSummaryItem.module.scss';

function NfumSummaryItem(props) { /* NOSONAR: pure declarative usage */
    const {
        id,
        value,
        label,
        className,
        valueClassName,
        labelClassName,
        labelPosition
    } = props;
    const translator = useContext(TranslatorContext);

    const containerClass = classNames(styles.container, className, {
        [styles.horizontal]: labelPosition === 'left'
    });

    const labelClass = classNames(styles.label, labelClassName);

    const valueClass = classNames(styles.value, valueClassName);

    return (
        <div id={id} className={containerClass}>
            { label && (
                <div id={`${id}_label`} className={labelClass}>
                    {translator(label)}
                </div>
            )}
            <div id={`${id}_value`} className={valueClass}>
                {value}
            </div>
        </div>
    );
}

export default NfumSummaryItem;

import { defineMessages } from 'react-intl';

export default defineMessages({
    specialConditionsTitle: {
        id: 'quoteandbind.ngh.views.policy-summary.special-conditions.specialConditionsTitle',
        defaultMessage: 'Special conditions'
    },
    specialConditionsApplyText: {
        id: 'quoteandbind.ngh.views.policy-summary.special-conditions.specialConditionsApplyText',
        defaultMessage: 'Special Conditions apply. Please read the details below before proceeding. If you don\'t comply with them it may affect your cover.'
    },
    expand: {
        id: 'quoteandbind.ngh.views.policy-summary.special-conditions.expand',
        defaultMessage: 'Expand'
    },
    collapse: {
        id: 'quoteandbind.ngh.views.policy-summary.special-conditions.collapse',
        defaultMessage: 'Collapse'
    }
});

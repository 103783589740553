import { defineMessages } from 'react-intl';

export default defineMessages({
    WithNFUMutualOnlineAccountYouCan: {
        id: 'quoteandbind.ngh.views.success.registered.WithNFUMutualOnlineAccountYouCan',
        defaultMessage: 'Here’s a reminder of some of the other things you can do online:'
    },
    accountText: {
        id: 'quoteandbind.ngh.views.success.registered.accountText',
        defaultMessage: 'Your policy documents are available to view in your online account.'
    },
    loginButton: {
        id: 'quoteandbind.ngh.views.success.registered.loginButton',
        defaultMessage: 'Log in '
    }
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    call: {
        id: 'nfum.platform.callOrContactInfo.call',
        defaultMessage: 'Call'
    },
    CallTimings: {
        id: 'nfum.platform.callOrContactInfo.CallTimings',
        defaultMessage: 'Mon to Fri: 8am-8pm'
    },
    SaturdayCallTimings: {
        id: 'nfum.platform.callOrContactInfo.SaturdayCallTimings',
        defaultMessage: 'Saturday: 9am-12.30pm'
    },
    SundayCallTimings: {
        id: 'nfum.platform.callOrContactInfo.SundayCallTimings',
        defaultMessage: 'Sunday: Closed'
    },
    contactYourAgency: {
        id: 'nfum.platform.callOrContactInfo.contactYourAgency',
        defaultMessage: 'Contact your local agency'
    },
    contactYourAgencyText: {
        id: 'nfum.platform.callOrContactInfo.contactYourAgencyText',
        defaultMessage: 'If you would like to speak to your local agency directly'
    },
    contactAgencyLink: {
        id: 'nfum.platform.callOrContactInfo.contactAgencyLink',
        defaultMessage: 'Contact your agency'
    },
    alterantivelyText: {
        id: 'nfum.platform.callOrContactInfo.alterantivelyText',
        defaultMessage: 'Alternatively, you can '
    },
    requestForCallbackText: {
        id: 'nfum.platform.callOrContactInfo.requestForCallbackText',
        defaultMessage: 'request a call back'
    },
    callMeBack: {
        id: 'nfum.platform.callOrContactInfo.callMeBack',
        defaultMessage: 'Call me back'
    },
    speakToCallCenter: {
        id: 'nfum.platform.callOrContactInfo.speakToCallCenter',
        defaultMessage: 'One of our UK based call centres will call you back'
    },
    callCenterText: {
        id: 'nfum.platform.callOrContactInfo.callCenterText',
        defaultMessage: '.'
    },
    moreInfoButton: {
        id: 'nfum.platform.callOrContactInfo.moreIfoButtonText',
        defaultMessage: 'More information'
    },
});

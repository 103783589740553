import React, { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { Icon } from '@jutro/components';
import styles from './SectionComponentWithTitle.module.scss';

const SectionComponentWithTitle = forwardRef(({
    children,
    sectionType = 'basic',
    title,
    additionalClasses,
    showHeaderCircle,
    headerCircleType,
    headerCircleData,
    nfumIconName,
    className,
    noContentPadding,
    completeLabel,
    IconOnClick
}, ref) => {
    const translator = useContext(TranslatorContext);

    let headerCircleComponent = '';

    if (headerCircleType === 'text') {
        headerCircleComponent = (
            <div className={styles.headerCircleContainer}>
                <span>{headerCircleData}</span>
            </div>
        );
    }

    if (headerCircleType === 'icon') {
        headerCircleComponent = (
            <div className={styles.headerCircleContainer}>
                <Icon icon={headerCircleData} />
            </div>
        );
    }

    const sectionClass = cx({
        [styles.sectionContent]: !noContentPadding
    });

    return (
        <section
            ref={ref}
            className={cx(
                styles.section,
                styles[sectionType],
                className,
                ...additionalClasses
            )}
        >
            { title && (
                <div className={styles.sectionTitle}>
                    { showHeaderCircle && headerCircleComponent }
                    { nfumIconName !== null && nfumIconName !== undefined && (
                        <div className={styles.iconContainer}>
                            <div className={cx(styles.icon, nfumIconName)} />
                        </div>
                    )}
                    <h2>{translator(title)}</h2>
                    { completeLabel !== null && completeLabel !== undefined && (
                        <div className={styles.paddingRightStyle}>
                            { translator(completeLabel) }
                        </div>
                    )}
                    { completeLabel !== null && completeLabel !== undefined && (
                        <div className={styles.iconContainer}>
                            <button
                                type="button"
                                className={styles.tickmarkIcon}
                                onClick={IconOnClick}
                                aria-label="complete icon"
                            />
                        </div>
                    )}
                </div>
            )}
            { (Array.isArray(children)
                ? children?.filter((x) => x !== null)?.length > 0
                : true) && (
                <div className={sectionClass}>
                    { children }
                </div>
            )}
        </section>
    );
});

SectionComponentWithTitle.defaultProps = {
    additionalClasses: [],
    showHeaderCircle: false,
    headerCircleData: '',
    headerCircleType: '',
    nfumIconName: null,
    className: ''
};

SectionComponentWithTitle.propTypes = {
    children: PropTypes.element.isRequired,
    sectionType: PropTypes.oneOf('basic', 'highlighted', 'extended').isRequired,
    title: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }).isRequired,
    additionalClasses: PropTypes.arrayOf(PropTypes.string),
    showHeaderCircle: PropTypes.bool,
    headerCircleData: PropTypes.string,
    headerCircleType: PropTypes.oneOf('icon', 'text'),
    nfumIconName: PropTypes.string,
    className: PropTypes.string
};
export default SectionComponentWithTitle;

import './RadioButtonField/RadioButtonFieldTheme.scss';
import './component-overrides.scss';
import './buttons/buttons.scss';
import './buttons/button-filled.scss';
import './buttons/button-outlined.scss';
import './buttons/button-text.scss';
import './helpers.scss';
import './icons.scss';
import './custom.scss';
import './global.scss';
import componentVars from './components-vars';
import colorVars from './colors-vars';
import spacingVars from './spacing-vars';
import typographyVars from './typography-vars';
import bordersVars from './borders-vars';

/* nfum styles patch for upgrade 11.5.2
#### custom nfum styles are not working.
---
Custom styles are not loaded properly,
we use ootb 'Consumer' internal styles name
+ ootb internalTheme flag which allows us to use custom styles */
export default {
    name: 'Consumer', // nfum custom: "Consumer" name: workaround to be able to use own styles
    rootStyle: {
        ...componentVars,
        ...colorVars,
        ...spacingVars,
        ...typographyVars,
        ...bordersVars,
        '--GW-BRAND-COLOR-1': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-BRAND-COLOR-2': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-BACKGROUND-COLOR-BODY': 'var(--NFUM-BODY-BACKGROUND-COLOR)',
        '--GW-BUTTON-FILLED-BACKGROUND-COLOR': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-COLOR-BACKGROUND-1': 'var(--NFUM-COLOR-WHITE)',
        '--GW-COLOR-STROKE': 'var(--NFUM-BORDER-BACKGROUND-COLOR)',
        '--GW-COLOR-BRAND-1': 'var(--NFUM-BORDER-LEFT-COLOR)',
        '--GW-BRAND-COLOR-1-HOVER': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-BUTTON-FILLED-BACKGROUND-COLOR-ACTIVE': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-BUTTON-FILLED-COLOR': 'var(--NFUM-COLOR-GREY)',
        '--GW-LINK-COLOR': 'var(--NFUM-COLOR-GREY)',
        '--GW-TOOLTIP-ICON-COLOR': 'var(--NFUM-COLOR-GREY)',
        '--GW-FONT-SIZE-LABEL': 'var(--GW-SPACING-5)',
        '--GW-LINE-HEIGHT-LABEL': 'var(--GW-SPACING-8)',
        '--GW-BUTTON-HEIGHT': 'calc(var(--GW-SPACING-4)*4.5)',
        '--GW-COLOR-TEXT-1': 'var(--NFUM-COLOR-PRIMARY)',
        '--GW-TEXT-COLOR-1': 'var(--NFUM-COLOR-GREY)',
        '--GW-COLOR-BACKGROUND-4': 'var(--NFUM-BODY-BACKGROUND-COLOR-1)',
        '--GW-BUTTON-OUTLINED-COLOR': 'var(--NFUM-COLOR-DARK)',
        '--GW-BUTTON-OUTLINED-BACKGROUND-COLOR': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-BUTTON-OUTLINED-COLOR-HOVER': 'var(--NFUM-COLOR-DARK)',
        '--GW-BUTTON-OUTLINED-BACKGROUND-COLOR-ACTIVE': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-BUTTON-TEXT-COLOR': 'var(--NFUM-COLOR-DARK)',
        '--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR-HOVER': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR-ACTIVE': 'var(--NFUM-COLOR-YELLOW-MAIN)',
        '--GW-TOOLTIP-COLOR': 'var(--NFUM-COLOR-DARK)',
        '--GW-TOOLTIP-BACKGROUND-COLOR': 'var(--NFUM-COLOR-WHITE)',
        '--GW-LINK-COLOR-HOVER': 'var(--NFUM-COLOR-DARK)',
        '--GW-SELECTOR-SELECTED-COLOR': 'var(--NFUM-COLOR-DARK)',
        '--GW-COLOR-TEXT-2': 'var(--NFUM-BORDER-BOTTOM-COLOR-GREY)',
        '--GW-LINK-COLOR-ACTIVE': 'var(--NFUM-COLOR-GREY)',
        '--GW-SELECTOR-BACKGROUND-COLOR-ACTIVE': 'var(--NFUM-BODY-BACKGROUND-COLOR-1)'
    },
    internalTheme: !0 // nfum custom: internalTheme: workaround to be able to use own styles
};

import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDependencies } from 'gw-portals-dependency-react';
import { Button } from '@jutro/components';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import styles from './DocumentsLinksComponent.module.scss';

function DocumentsLinksComponent(props) {
    const { documents, pushFileDownloadInfo, isCmsDocument = false } = props;
    const { PolicyService } = useDependencies('PolicyService');
    const { env } = appConfig;

    const getNameWithFormat = useCallback((doc) => {
        if (isCmsDocument) {
            return doc.displayName;
        }
        let docFormat = null;
        const mimeTypeParts = doc?.mimeType?.split('/');
        if (mimeTypeParts) {
            docFormat = _.last(mimeTypeParts)?.toUpperCase();
        }
        return docFormat ? `${doc.name} (${docFormat})` : doc.name;
    }, [isCmsDocument]);

    const onDocumentClicked = useCallback(async (doc) => {
        let docUrl = '';
        if (isCmsDocument) {
            docUrl = doc.document.url;
            if (docUrl.startsWith('/')) {
                docUrl = `${env.NFUM_CMS_URL}${doc.document.url}`;
            }
            pushFileDownloadInfo(getNameWithFormat(doc), docUrl);
            window.open(docUrl, '_blank');
        } else {
            pushFileDownloadInfo(getNameWithFormat(doc), 'p8-internal-doc');
            window.open(PolicyService.downloadDocument(doc.publicID, doc.sessionID));
        }
    }, [isCmsDocument, PolicyService, pushFileDownloadInfo, getNameWithFormat, env]);

    return (
        <div>
            { documents?.map((doc) => {
                return (
                    <div
                        className={styles.link}
                        key={doc.publicID}
                    >
                        <div className={styles.nfumDocumentLinkContainer}>
                            <Button
                                id="doc-link"
                                type="text"
                                icon="cust-download"
                                onClick={() => onDocumentClicked(doc)}
                                iconPosition="left"
                            >
                                { getNameWithFormat(doc) }
                            </Button>
                        </div>
                    </div>
                );
            }) }
        </div>
    );
}

export default DocumentsLinksComponent;

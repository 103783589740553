import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './OnlineAccountStatusUnKnown.metadata.json5';
import styles from './OnlineAccountStatusUnKnown.module.scss';
import AccessListItems from '../AccessListItems/AccessListItems';

function OnlineAccountStatusUnKnown() {
    const overrideProps = {
        accessListItems: {
            status: 'incomplete'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            accessListItems: AccessListItems
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default OnlineAccountStatusUnKnown;

import _ from 'lodash';
import {
    useState,
    useCallback,
    useEffect,
    useRef
} from 'react';
import useLatest from './useLatest';

const defaultOptions = {
    retryTimes: 0,
    retryDelayFactor: 1,
    retryDelayInSeconds: 0,
    initialIsLoading: false
};

const wait = (seconds) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

function useCallRetry(
    serviceCallPromise,
    options = {},
    runIfDependenciesChanged = [],
    shouldBeCalled = true
) {
    const optionsRef = useRef(Object.assign({}, defaultOptions, options));
    const latestServiceCallPromise = useLatest(serviceCallPromise);
    const [retryState, setRetryState] = useState({});
    const [state, setState] = useState({
        loading: optionsRef.current.initialIsLoading,
        data: null,
        error: null
    });

    const setResponse = useCallback((loading, data = null, error = null) => {
        setState({ loading, data, error });
    }, []);

    const retry = useCallback(() => {
        setRetryState({});
    }, []);

    useEffect(() => {
        if (!shouldBeCalled) {
            setResponse(false);
            return;
        }
        let retryCount = 0;
        const start = Date.now();
        const fetchData = async function fetchData() {
            try {
                setResponse(true);
                if (!_.isFunction(latestServiceCallPromise.current)) {
                    throw new Error('First argument of a hook must be async function');
                }
                retryCount += 1;
                const result = await latestServiceCallPromise.current();
                setResponse(false, result);
            } catch (err) {
                console.error('callRetry error: ', err);
                const durationInSeconds = Math.floor((Date.now() - start) / 1000);
                const {
                    retryTimes,
                    retryDelayInSeconds,
                    timeoutDurationSeconds
                } = optionsRef.current;
                if (durationInSeconds < timeoutDurationSeconds && retryCount < retryTimes) {
                    const delay = retryDelayInSeconds * retryCount;
                    await wait(delay);
                    fetchData();
                } else {
                    optionsRef.current.retryTimes = 0;
                    setResponse(false, null, err);
                }
            }
        };
        fetchData();
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...runIfDependenciesChanged,
        latestServiceCallPromise,
        optionsRef,
        retryState,
        setResponse,
        shouldBeCalled
    ]);

    return {
        ...state,
        retry,
    };
}

export default useCallRetry;

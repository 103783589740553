import { NfumLoader } from 'nfum-components-platform-react';
import React, { useEffect } from 'react';

const HomeRedirect = () => {
    useEffect(() => {
        window.location.href = 'https://www.nfumutual.co.uk';
    }, []);
    return (
        <NfumLoader />
    );
};

export default HomeRedirect;

import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Loader } from '@jutro/components';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { withValidation, validationPropTypes, validationDefaultProps } from 'gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { VehicleInfoLookupService } from 'gw-capability-vehicleinfo';
import VehicleImageLookupService from './VehicleImageLookupService';
import metadata from './VehicleInfoLookupComponent.metadata.json5';
import styles from './VehicleInfoLookupComponent.module.scss';
import './VehicleInfoLookupComponent.messages';

class VehicleInfoLookupComponent extends Component {
    /**
     * @memberof gw-capability-address-react.VehicleInfoLookupComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {boolean} propTypes.displayImage - flag for specifying if
     *  image of the vehicle should be displayed
     */
    static propTypes = {
        value: PropTypes.shape({ postalCode: PropTypes.string }),
        phoneWide: PropTypes.shape({}),
        path: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onValueChange: PropTypes.func,
        onValidate: PropTypes.func,
        displayImage: PropTypes.bool,
        displayAllFields: PropTypes.bool,
        layout: PropTypes.oneOf(['normal', 'reversed', 'full', 'indent']),
        labelPosition: PropTypes.oneOf(['left', 'right', 'top']),
        showVinInfoText: PropTypes.bool,
        showOptional: PropTypes.bool,
        authHeader: PropTypes.shape({}).isRequired,
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        ...validationPropTypes,
        ...validationDefaultProps
    };

    static defaultProps = {
        onValidate: undefined,
        value: undefined,
        onValueChange: undefined,
        displayImage: true,
        displayAllFields: true,
        labelPosition: 'left',
        layout: 'normal',
        showVinInfoText: false,
        showOptional: true,
        phoneWide: {
            labelPosition: 'top'
        },
    };

    state = {
        showLoader: false,
        readonlyOverride: false,
        stockPhoto: undefined,
        showFieldsOverride: true
    };

    componentDidMount() {
        const {
            id,
            onValidate,
            displayAllFields,
            isComponentValid,
            registerComponentValidation
        } = this.props;

        registerComponentValidation(this.getIsFormValid);

        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        this.setState({ showFieldsOverride: displayAllFields });
    }

    componentDidUpdate(prevProps) {
        const {
            onValidate,
            id,
            isComponentValid,
            hasValidationChanged,
            value: newVehicleData
        } = this.props;

        const { value: oldVehicleData } = prevProps;
        const isSameVehicle = _.isEqual(newVehicleData.value, oldVehicleData.value);

        if (onValidate && (!isSameVehicle || hasValidationChanged)) {
            onValidate(isComponentValid, id);
        }
    }

    getIsFormValid = () => {
        const { value: vehicleData } = this.props;
        return vehicleData.aspects.subtreeValid && vehicleData.aspects.valid;
    };

    setCarYears = () => {
        const currentYear = new Date().getUTCFullYear();
        const { oldestCarYear } = config.personalAutoConfig;

        // _.range is not inclusive. Need to add -1 to get correct years
        const yearRange = _.range(currentYear, oldestCarYear - 1);

        return yearRange.map(_.toString).map((year) => ({
            code: year,
            name: year
        }));
    };

    handleChange = (value, changedPath) => {
        const { path, onValueChange } = this.props;
        onValueChange(value, `${path}.${changedPath}`);
    };

    handleBlur = () => {
        const { value: vehicleData, displayAllFields, authHeader } = this.props;
        const vinNumber = _.get(vehicleData, 'vin.value');

        if (vinNumber === undefined) {
            this.setState({ showFieldsOverride: displayAllFields });

            return;
        }

        this.setState({ showLoader: true });

        VehicleInfoLookupService.lookupVehicleInfoBasedOnVin(vinNumber, authHeader)
            .then((vehicleInfo) => {
                const foundVehicle = _.get(vehicleInfo, 'errorCode') === undefined;
                const vehicleMake = _.get(vehicleInfo, 'make', '');
                const vehicleModel = _.get(vehicleInfo, 'model', '');
                const vehicleYear = _.get(vehicleInfo, 'year', '');

                let stockPhoto;

                if (foundVehicle) {
                    stockPhoto = VehicleImageLookupService.lookupPhoto(
                        vehicleYear,
                        vehicleMake,
                        vehicleModel
                    );

                    if (!displayAllFields) {
                        this.setState({ showFieldsOverride: true });
                    }
                } else if (!displayAllFields) {
                    this.setState({ showFieldsOverride: false });
                }

                this.handleChange(vehicleMake, 'make');
                this.handleChange(vehicleModel, 'model');
                this.handleChange(vehicleYear, 'year');

                this.setState({ showLoader: false, readonlyOverride: foundVehicle, stockPhoto });
            })
            .catch(() => {
                this.setState({ showLoader: false });
            });
    };

    render() {
        const {
            showLoader, readonlyOverride, stockPhoto, showFieldsOverride
        } = this.state;
        const {
            value: vehicleData,
            displayImage,
            phoneWide,
            layout,
            labelPosition,
            showVinInfoText,
            showOptional
        } = this.props;

        const overrideProps = {
            '@field': {
                showOptional: showOptional,
                layout: layout,
                labelPosition: labelPosition,
                phoneWide
            },
            vinInfoContainer: {
                visible: showVinInfoText && !showFieldsOverride
            },
            vehicleVin: {
                onBlur: this.handleBlur
            },
            vehicleMake: {
                readOnly: readonlyOverride,
                visible: showFieldsOverride
            },
            vehicleModel: {
                readOnly: readonlyOverride,
                visible: showFieldsOverride
            },
            vehicleYear: {
                readOnly: readonlyOverride,
                visible: showFieldsOverride,
                availableValues: this.setCarYears()
            },
            imageContainer: {
                visible: displayImage && stockPhoto !== undefined,
                className: classNames(styles.imageContainer, {
                    digitalFieldComponentAliginLeft: labelPosition === 'left'
                })
            },
            imageComponent: {
                src: stockPhoto
            }
        };
        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles
        };
        return (
            <>
                <Loader loaded={!showLoader} />
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={vehicleData}
                    overrideProps={overrideProps}
                    onValueChange={this.handleChange}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </>
        );
    }
}

export default withViewModelService(
    withAuthenticationContext(withValidation(VehicleInfoLookupComponent))
);

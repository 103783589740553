import {
    useCallback
} from 'react';
import _ from 'lodash';
import { useCms } from 'nfum-cms-react';

const useCmsDocumentsRetriever = () => {
    const { getDocuments } = useCms();
    const YES_CODE = 'Yes';
    const LEVEL_OF_COVER_NAME = 'Level of Cover';
    const LEGAL_EXPENSES_EXTRA_KEY = 'Extra';
    const LEGAL_EXPENSES_STANDARD_KEY = 'Standard';

    const cmsDocId = Object.freeze({
        IPID_BUILDINGS: 'ipid_buildings',
        IPID_CONTENTS_TIER_1: 'ipid_contents_tier_1',
        IPID_CONTENTS_TIER_2: 'ipid_contents_tier_2',
        IPID_CONTENTS_TIER_3: 'ipid_contents_tier_3',
        IPID_BUILDINGS_CONTENTS_TIER_1: 'ipid_buildings_contents_tier_1',
        IPID_BUILDINGS_CONTENTS_TIER_2: 'ipid_buildings_contents_tier_2',
        IPID_BUILDINGS_CONTENTS_TIER_3: 'ipid_buildings_contents_tier_3',
        IPID_CONTENTS_AWAY_TIER_1: 'ipid_contents_away_tier_1',
        IPID_CONTENTS_AWAY_TIER_2: 'ipid_contents_away_tier_2',
        IPID_LEX: 'ipid_lex',
        IPID_LEX_EXTRA: 'ipid_lex_extra',
        IPID_HOME_EMERGENCY: 'ipid_home_emergency',
        IPID_CYCLING_PROTECTION: 'ipid_cycling_protection',
        STATUS_DISCLOSURE: 'status_disclosure'
    });

    const tierLevel = Object.freeze({
        ONE: 'tierLevel1',
        TWO: 'tierLevel2',
        THREE: 'tierLevel3'
    });

    const coverageId = Object.freeze({
        BUILDINGS: 'HOMBuildingsCov',
        CONTENTS: 'HOMContentsCov',
        CONTENTS_AWAY: 'HOMContentsAwayCov',
        HOME_EMERGENCY: 'HOMHomeEmergencyCov',
        LEGAL_EXPENSE: 'LEXPersonalLegalExpensesCov',
        PEDAL_CYCLE_EXT: 'HOMContentsAwayCovPedalCycleExtension',
        HOM_CONTENTS_AWAY_BICYCLE_EXTENSION: 'HOMContentsAwayCovPedalCycleExtension'
    });

    const getIsBuildingsCovSelected = useCallback((jobVM) => {
        const homeLineCoverages = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const buildingsCov = homeLineCoverages
            ?.find((cov) => cov.publicID === coverageId.BUILDINGS);
        return buildingsCov?.selected;
    }, [coverageId]);

    const getIsContentsCovSelected = useCallback((jobVM) => {
        const homeLineCoverages = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const contentsCov = homeLineCoverages
            ?.find((cov) => cov.publicID === coverageId.CONTENTS);
        return contentsCov?.selected;
    }, [coverageId]);

    const getIsContentsAwayCovSelected = useCallback((jobVM) => {
        const homeLineCoverages = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const contentsCov = homeLineCoverages
            ?.find((cov) => cov.publicID === coverageId.CONTENTS_AWAY);
        return contentsCov?.selected;
    }, [coverageId]);

    const getIsLegalExpensesCovSelected = useCallback((jobVM) => {
        const lexLineCoverages = _.get(jobVM, 'lobData.value.lexLine.lineCoverages.coverages');
        const legalExpCov = lexLineCoverages
            ?.find((cov) => cov.publicID === coverageId.LEGAL_EXPENSE);
        return legalExpCov?.terms
            ?.find((term) => term.name === LEVEL_OF_COVER_NAME)?.chosenTermValue;
    }, [coverageId]);

    const getIsHomeEergencyCovSelected = useCallback((jobVM) => {
        const homeLineCoverages = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const contentsCov = homeLineCoverages
            ?.find((cov) => cov.publicID === coverageId.HOME_EMERGENCY);
        return contentsCov?.selected;
    }, [coverageId]);

    const getIsContentsAwayPedalCycleSelected = useCallback((jobVM) => {
        const homeLineCoverages = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const contentsCov = homeLineCoverages
            ?.find((cov) => cov.publicID === coverageId.CONTENTS_AWAY);
        return contentsCov?.terms
            ?.find((term) => term.publicID === coverageId.HOM_CONTENTS_AWAY_BICYCLE_EXTENSION)
            ?.chosenTermValue === YES_CODE;
    }, [coverageId]);

    const isUnSpecifiedContentsAwaySelected = useCallback((jobVM) => {
        return _.get(jobVM, 'lobData.value.homeLine.lineCoverages.isUnspecifiedContentsAwaySelected');
    }, []);

    const getIsTierLevel = useCallback((jobVM, level) => {
        return _.get(jobVM, 'tierLevel_NFUM.value.code') === level;
    }, []);

    const docsConfig = [
        {
            cmsDocId: cmsDocId.IPID_BUILDINGS,
            getVisibility: (jobVM) => {
                return getIsBuildingsCovSelected(jobVM)
                    && !getIsContentsCovSelected(jobVM);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_TIER_1,
            getVisibility: (jobVM) => {
                return !getIsBuildingsCovSelected(jobVM)
                    && getIsContentsCovSelected(jobVM)
                    && getIsTierLevel(jobVM, tierLevel.ONE);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_TIER_2,
            getVisibility: (jobVM) => {
                return !getIsBuildingsCovSelected(jobVM)
                    && getIsContentsCovSelected(jobVM)
                    && getIsTierLevel(jobVM, tierLevel.TWO);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_TIER_3,
            getVisibility: (jobVM) => {
                return !getIsBuildingsCovSelected(jobVM)
                    && getIsContentsCovSelected(jobVM)
                    && getIsTierLevel(jobVM, tierLevel.THREE);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_BUILDINGS_CONTENTS_TIER_1,
            getVisibility: (jobVM) => {
                return getIsBuildingsCovSelected(jobVM)
                    && getIsContentsCovSelected(jobVM)
                    && getIsTierLevel(jobVM, tierLevel.ONE);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_BUILDINGS_CONTENTS_TIER_2,
            getVisibility: (jobVM) => {
                return getIsBuildingsCovSelected(jobVM)
                    && getIsContentsCovSelected(jobVM)
                    && getIsTierLevel(jobVM, tierLevel.TWO);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_BUILDINGS_CONTENTS_TIER_3,
            getVisibility: (jobVM) => {
                return getIsBuildingsCovSelected(jobVM)
                    && getIsContentsCovSelected(jobVM)
                    && getIsTierLevel(jobVM, tierLevel.THREE);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_AWAY_TIER_1,
            getVisibility: (jobVM) => {
                return getIsTierLevel(jobVM, tierLevel.ONE)
                && getIsContentsAwayCovSelected(jobVM)
                && isUnSpecifiedContentsAwaySelected(jobVM);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_AWAY_TIER_2,
            getVisibility: (jobVM) => {
                return getIsTierLevel(jobVM, tierLevel.TWO)
                && getIsContentsAwayCovSelected(jobVM)
                && isUnSpecifiedContentsAwaySelected(jobVM);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_LEX,
            getVisibility: (jobVM) => {
                return getIsLegalExpensesCovSelected(jobVM) === LEGAL_EXPENSES_STANDARD_KEY;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_LEX_EXTRA,
            getVisibility: (jobVM) => {
                return getIsLegalExpensesCovSelected(jobVM) === LEGAL_EXPENSES_EXTRA_KEY;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_HOME_EMERGENCY,
            getVisibility: (jobVM) => {
                return getIsHomeEergencyCovSelected(jobVM);
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CYCLING_PROTECTION,
            getVisibility: (jobVM) => {
                return getIsContentsAwayPedalCycleSelected(jobVM)
                && getIsContentsAwayCovSelected(jobVM);
            }
        },
        {
            cmsDocId: cmsDocId.STATUS_DISCLOSURE,
            getVisibility: () => true
        }
    ];

    const getDocumentsToDisplay = async (jobVM) => {
        try {
            const documentsIdsToDisplay = [];

            docsConfig.forEach((docConfig) => {
                if (docConfig.getVisibility(jobVM)) {
                    documentsIdsToDisplay.push(docConfig.cmsDocId);
                }
            });

            const allDocs = await getDocuments();
            return allDocs
                .filter((doc) => documentsIdsToDisplay.includes(doc.cmsDocId))
                .sort((a, b) => a.priority - b.priority);
        } catch (err) {
            throw err;
        }
    };

    return {
        getDocumentsToDisplay
    };
};

export default useCmsDocumentsRetriever;

import React, { useEffect } from 'react';
import {
    useHistory
} from 'react-router-dom';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './SessionTimeOut.module.scss';
import metadata from './SessionTimeOut.metadata.json5';

const SessionTimeOut = () => {
    const history = useHistory();
    const refreshPage = () => {
        const baseUrl = '/quote-ngh';
        history.push(baseUrl);
        window.localStorage.setItem('sessionTimeOut', false);
    };

    useEffect(() => {
        if (window.performance) {
            if (performance.getEntriesByType('navigation')[0].type === 'reload') {
                window.localStorage.removeItem('sessionTimeOut');
            }
        }
    });

    const overrideProps = {
        refreshLink: {
            onClick: refreshPage
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            className={styles.modalWrapper}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

SessionTimeOut.propTypes = {
};
SessionTimeOut.defaultProps = {
};

export default SessionTimeOut;

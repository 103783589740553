import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '@jutro/components';
import styles from './HelpIcon.module.scss';

function HelpIcon({ onClick }) {
    return (
        <Button
            icon="mi-help_outline"
            className={styles.helpIcon}
            onClick={onClick}
        />
    );
}

HelpIcon.propTypes = {
    onClick: PropTypes.func
};

HelpIcon.defaultProps = {
    onClick: _.noop
};

export default HelpIcon;

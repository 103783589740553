/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';

import messages from './NfumAccordionHeader.messages';
import styles from './NfumAccordionHeader.module.scss';

function NfumAccordionHeader(props) {
    const translator = useContext(TranslatorContext);

    const {
        isOpen,
        title,
        openMessage,
        icon,
        showCloseMessage,
        onClick
    } = props;

    return (
        <div className={styles.accordionTitleContainer} onClick={onClick} onKeyDown={onClick}>
            <h3 className={styles.accordionTitle}>{ translator(title) }</h3>
            {showCloseMessage && (
                <div className={styles.accordionCloseMessageContainer} aria-hidden="true">
                    <span className={styles.accordionOpenMessage}>
                        { isOpen ? translator(messages.close) : translator(openMessage) }
                    </span>
                    <div className={styles.accordionChevronContainer}>
                    {!icon && <Icon className={styles.arrowIcon} icon={isOpen ? "gw-chevron-right" : "gw-expand-more"} aria-label={ translator(title) }/>}
                    {icon && <Icon className={isOpen ? `${styles.arrowIcon} ${styles.rotatedIcon}`: `${styles.arrowIcon}`} icon={`gw-${icon}`} aria-label={ translator(title) }/>}
                    </div>
                </div>
            )}
        </div>
    );
}

NfumAccordionHeader.propTypes = {
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    openMessage: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    isOpen: PropTypes.bool
};

NfumAccordionHeader.defaultProps = {
    openMessage: {
        id: 'nfum.platform.label.accordion.Open',
        defaultMessage: 'Open'
    }
};

export default NfumAccordionHeader;
import React, { useContext } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

const wizardContext = React.createContext(null);

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * Use this to have your wizard component running within the wizard context.
 * It will set in the (@link WrappedComponent) the current context value
 * as given by the nearest context provider for the given context.
 *
 * @function withWizardContext
 * @memberof module:gw-portals-wizard-react
 *
 * @param {ReactElement} WrappedComponent - Component to run within the context
 * @returns {ReactElement}
 */
export function withWizardContext(WrappedComponent) {
    function WithWizardContext(props) {
        const currentContext = useContext(wizardContext);
        return <WrappedComponent {...currentContext} {...props} />;
    }
    hoistNonReactStatic(WithWizardContext, WrappedComponent);
    WithWizardContext.displayName = `WithWizardContext(${getDisplayName(WrappedComponent)})`;
    return WithWizardContext;
}

/**
 * Wizard react context object
 *
 * @constant WizardContext
 * @memberof module:gw-portals-wizard-react
 */
export const WizardContext = wizardContext;

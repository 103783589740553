/* eslint-disable */
import React, { useContext, useState } from 'react';
import { InputField, Icon } from '@jutro/components';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import NfumFieldLabel from '../NfumFieldLabel/NfumFieldLabel';
import styles from './NfumInputField.module.scss';
import messages from './NfumInputField.messages';

function NfumInputField(props) { /* NOSONAR: pure declarative usage */
    const {
        value,
        success,
        error,
        showEdit,
        onValidationChange,
        onBlur,
        className,
        isFullSize,
        immediateInfoMessages,
        descriptionOfItem,
        label,
        showOptional,
        labelClassName,
        maxLength,
        maxLengthValidationMessage,
        onValueChange,
        iconPosition,
        showCurrency
    } = props;
    const translator = useContext(TranslatorContext);
    const ERROR_ICON = 'gw-close';
    const CHECK_ICON = 'gw-check';
    const [immediateInfoMessagesInternal,
        updateImmediateInfoMessagesInternal] = useState(immediateInfoMessages || []);
    const [currentValidation, updateCurrentValidation] = useState(true);
    const [showState, updateShowState] = useState(false);
    const [isErrorsVisible, updateIsErrorsVisible] = useState(false);
    const showError = value !== undefined && (error || !currentValidation);
    let icon;
    
    if (showError) {
        icon = ERROR_ICON;
    } else if (success || value) {
        icon = CHECK_ICON;
    } else if (showOptional) {
        icon = CHECK_ICON;
    } else {
        icon = ERROR_ICON
    }
    const iconClass = classNames(styles.nfumInputIcon, {
        [styles.errorIcon]: icon === ERROR_ICON
    });
    const inputClass = classNames(styles.nfumInputField, className, {
        [styles.fullSize]: isFullSize,
        [styles.erroredInput]: error
    });

    const iconComponent = <Icon icon={icon} className={iconClass} />;

    const onValidate = (isValid, path) => {
        updateCurrentValidation(isValid);
        onValidationChange(isValid, path);
    };

    const validateFieldLength = (data) => {
        if (data?.length >= maxLength) {
            const infoMessages = immediateInfoMessages || [];
            if (maxLengthValidationMessage !== '') {
                infoMessages.push(maxLengthValidationMessage
                    || translator(messages.reachedMaximumFieldLength));
                updateImmediateInfoMessagesInternal(infoMessages);
            }
        } else {
            const infoMessages = immediateInfoMessages || [];
            updateImmediateInfoMessagesInternal(infoMessages);
        }
    };

    const handleOnValueChange = (data, path) => {
        if (onValueChange) {
            onValueChange(data, path);
        }
        validateFieldLength(data);
        updateShowState(true);
    };

    const handleBlur = (event, data) => {
        if (onBlur) {
            onBlur(data.value, data.model);
        } else {
            handleOnValueChange(_.trim(data?.value), data.model);
        }
        updateIsErrorsVisible(true);
        updateShowState(true);
    };

    return (
        <div className={styles.nfumInputContainer}>
            { label && (
                <NfumFieldLabel
                    {...props}
                    label={label}
                    showOptional={showOptional}
                    className={labelClassName}
                />
            )}
            <div>
                {descriptionOfItem && (
                    <>
                        <p>{descriptionOfItem.valuableDescription}</p>
                        <p>{descriptionOfItem.valuableExampleDescription}</p>
                    </>
                )}
            </div>
            <div className={styles.nfumInput}>
                <InputField
                    {...props}
                    icon={(showEdit && 'gw-edit') || (showCurrency && 'cust-currency-pound')}
                    iconPosition= {iconPosition}
                    className={inputClass}
                    onValidationChange={onValidate}
                    onBlur={handleBlur}
                    onValueChange={handleOnValueChange}
                    hideLabel
                    showErrors={isErrorsVisible}
                />
                {(showState && !isFullSize && !showOptional) && iconComponent}
            </div>
            { immediateInfoMessagesInternal
                && (
                    <div>
                        { immediateInfoMessagesInternal.map((info, index) => {
                            // eslint-disable-next-line react/no-array-index-key
                            return (<div key={index}>{info}</div>);
                        }) }
                    </div>
                )
            }
        </div>
    );
}

export default NfumInputField;

import { defineMessages } from 'react-intl';

export default defineMessages({
    confirmModalTitle: {
        id: 'nfum.platform.nfumConfirmModal.confirmModalTitle',
        defaultMessage: 'Cover removal'
    },
    goBack: {
        id: 'nfum.platform.nfumConfirmModal.goBack',
        defaultMessage: 'Go back'
    },
    confirmModalInfo: {
        id: 'nfum.platform.nfumConfirmModal.confirmModalInfo',
        defaultMessage: 'Please be aware this change will end the following element of cover on your policy from the date you have requested. Are you sure you wish to continue?'
    },
    removingSpecifiedBicycles: {
        id: 'nfum.platform.nfumConfirmModal.removingSpecifiedBicycles',
        defaultMessage: 'Specified Bicycles (if present)'
    },
    removingCyclingProtection: {
        id: 'nfum.platform.nfumConfirmModal.removingCyclingProtection',
        defaultMessage: 'Cycling Protection cover (if present)'
    },
    doYouWishToContinue: {
        id: 'nfum.platform.nfumConfirmModal.doYouWishToContinue',
        defaultMessage: 'Do you wish to continue?'
    },
    yesRemoveCover: {
        id: 'nfum.platform.nfumConfirmModal.yesRemoveCover',
        defaultMessage: 'Yes, remove cover'
    },
    confirmModalTitleVariant2: {
        id: 'nfum.platform.nfumConfirmModal.confirmModalTitleVariant2',
        defaultMessage: 'Contents Away From The Home'
    },
    yesRemoveCoverVariant2: {
        id: 'nfum.platform.nfumConfirmModal.yesRemoveCoverVariant2',
        defaultMessage: 'Yes, continue'
    },
    removingContentsAwayWillAlsoRemove: {
        id: 'nfum.platform.nfumConfirmModal.removingContentsAwayWillAlsoRemove',
        defaultMessage: 'Removing Contents away from the home cover as part of your quote will also remove:'
    },
    areYouSure: {
        id: 'nfum.platform.nfumConfirmModal.areYouSure',
        defaultMessage: 'Are you sure?'
    },
    no: {
        id: 'nfum.platform.nfumConfirmModal.no',
        defaultMessage: 'No'
    },
    yes: {
        id: 'nfum.platform.nfumConfirmModal.yes',
        defaultMessage: 'Yes'
    },
    cancel: {
        id: 'nfum.platform.nfumConfirmModal.cancel',
        defaultMessage: 'Cancel'
    },
    ok: {
        id: 'nfum.platform.nfumConfirmModal.ok',
        defaultMessage: 'OK'
    },
    confirmationPrintedCopyTitle: {
        id: 'nfum.platform.nfumConfirmModal.confirmationPrintedCopyTitle',
        defaultMessage: 'Confirmation'
    },
    confirmationPrintedCopyText1: {
        id: 'nfum.platform.nfumConfirmModal.confirmationPrintedCopyText1',
        defaultMessage: 'You\'ve chosen to receive a copy of your policy documents by post.'
    },
    confirmationPrintedCopyText2: {
        id: 'nfum.platform.nfumConfirmModal.confirmationPrintedCopyText2',
        defaultMessage: 'You can change your paperless preferences at any time by getting in touch with us.'
    },
    confirmationPrintedCopyText3: {
        id: 'nfum.platform.nfumConfirmModal.confirmationPrintedCopyText3',
        defaultMessage: 'With an NFU Mutual online account you can also choose to view, download and print your policy documents at any time.'
    }
});

import React, { useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import './BicyclesSummary.messages';
import metadata from './BicyclesSummary.metadata.json5';
import styles from './BicyclesSummary.module.scss';
import useCurrency from '../../../hooks/useCurrency';

function BicyclesSummary(props) {
    const { scheduleItems } = props;
    const currencyFormatter = useCurrency();

    const generateBicyclesOverrides = useCallback(() => {
        const coverageItems = scheduleItems || [];
        const covOverrides = coverageItems.map((changedField, index) => {
            return {
                [`bicycleName${index}`]: {
                    content: changedField.itemData.ArticleType.typeCodeValue
                },
                [`articleLimit${index}`]: {
                    value: currencyFormatter
                        .formatCurrency(changedField.itemData?.ArticleLimit?.bigDecimal, true)
                }
            };
        });
        return Object.assign({}, ...covOverrides);
    }, [scheduleItems, currencyFormatter]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            className: 'riskObjectPropertyContainer',
            valueClassName: 'riskObjectPropertyValue'
        },
        bicycles: {
            visible: scheduleItems.length > 0,
            data: scheduleItems
        },
        ...generateBicyclesOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default BicyclesSummary;

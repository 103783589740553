import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chevron } from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { UWBlockingPoint } from 'gw-portals-edge-validation-js';
import styles from './QuoteClauseTable.module.scss';
import QuoteTableAccordionCardIterableComponent from '../QuoteTableAccordionCardIterableComponent/QuoteTableAccordionCardIterableComponent';
import UnderwritingIssues from '../UnderwritingIssues/UnderwritingIssues';
import metadata from './QuoteClauseTable.metadata.json5';

const BLOCKS_QUOTES = [UWBlockingPoint.BLOCKS_QUOTE, UWBlockingPoint.BLOCKS_QUOTE_RELEASE];

function QuoteClauseTableView({
    breakpoint,
    formData,
    onValidationChange,
    onValueChange,
    overrideProps,
    quoteData,
}) {
    const { underwritingIssues, quoteID, filterUWIssuesInCustomOffering } = quoteData;
    const findBlockingPoint = useCallback(() => {
        return underwritingIssues.some((offeringIssue) => {
            return BLOCKS_QUOTES.includes(offeringIssue.currentBlockingPoint);
        });
    }, [underwritingIssues]);

    const mergedOverrideProps = {
        '@field': {
            // apply to all fields
            onValueChange
        },
        underWritingIssues: {
            underwritingIssues: underwritingIssues,
            blockQuote: findBlockingPoint(),
            quoteID: quoteID,
            filterUWIssuesInCustomOffering
        },
        paymentToggle: {
            visible: !findBlockingPoint()
        },
        tableContainer: {
            visible: !findBlockingPoint()
        },
        tableHeader: {
            visible: !findBlockingPoint()
        },
        ...overrideProps,
    };

    const formMetadata = useMemo(() => {
        const { componentContent: { content } } = metadata;

        if (breakpoint === 'phoneWide' || breakpoint === 'phone') {
            return content.filter((item) => item.id !== 'desktopTable');
        }

        return content.filter(
            (item) => item.id !== 'phoneTable' && item.id !== 'mobileAccordionHeader'
        );
    }, [breakpoint]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            chevron: Chevron,
            quotetableaccordioncarditerablecomponent: QuoteTableAccordionCardIterableComponent,
            underwritingissues: UnderwritingIssues
        }
    };

    return (
        <ViewModelForm
            uiProps={formMetadata}
            model={formData}
            overrideProps={mergedOverrideProps}
            onValueChange={onValueChange}
            onValidationChange={onValidationChange}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

QuoteClauseTableView.propTypes = {
    breakpoint: PropTypes.string.isRequired,
    formData: PropTypes.object.isRequired,
    onValidationChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    overrideProps: PropTypes.object.isRequired,
    quoteData: PropTypes.object.isRequired,
};

export default QuoteClauseTableView;

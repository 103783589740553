import { defineMessages } from 'react-intl';

export default defineMessages({
    contentsInsurance: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.contentsInsurance',
        defaultMessage: 'Contents insurance'
    },
    noOfBedrooms: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.noOfBedrooms',
        defaultMessage: 'Number of bedrooms'
    },
    contentsLevelLimit: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.contentsLevelLimit',
        defaultMessage: 'Contents level limit'
    },
    contentsReplacementCost: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.contentsReplacementCost',
        defaultMessage: 'Contents Replacement cost'
    },
    contentsAccidentalDamage: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.contentsAccidentalDamage',
        defaultMessage: 'Contents accidental damage'
    },
    confirmationText1: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.confirmationText1',
        defaultMessage: 'You have confirmed that:'
    },
    confirmationText2Yes: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.confirmationText2Yes',
        defaultMessage: 'The buildings you want to insure have sustained loss of damage by flood,subsidence, heave or landslip'
    },
    confirmationText2No: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.confirmationText2No',
        defaultMessage: 'The buildings you want to insure have never sustained loss of damage by flood,subsidence, heave or landslip'
    },
    confirmationText3Yes: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.confirmationText3Yes',
        defaultMessage: 'The buildings you want to insure are at an immediate risk of flooding'
    },
    confirmationText3No: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.confirmationText3No',
        defaultMessage: 'The buildings you want to insure are not at an immediate risk of flooding'
    },
    confirmationText4: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.confirmationText4',
        defaultMessage: 'You don\'t use your home for business purposes other than as a home office'
    },
    confirmationText5: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.confirmationText5',
        defaultMessage: 'No customers or clients visit your home for business purposes'
    },
    confirmedText4Tooltip: {
        id: 'quoteandbind.ngh.views.policy-summary.confirmedText4Toolip',
        defaultMessage: 'By this we mean only clerical and administrative tasks that you undertake from home and where no goods are produced'
    },
    excess: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.excesses',
        defaultMessage: 'Excesses'
    },
    leakingAndFrozenWater: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.leakingAndFrozenWater',
        defaultMessage: 'Leaking and Frozen Water'
    },
    subsidence: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.subsidence',
        defaultMessage: 'Subsidence'
    },
    flood: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.flood',
        defaultMessage: 'Flood'
    },
    voluntary: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.voluntary',
        defaultMessage: 'Voluntary'
    },
    otherExcessesAndAdjustments: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.otherExcessesAndAdjustments',
        defaultMessage: 'Other excesses and adjustments'
    },
    referToDocuments: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.referToDocuments',
        defaultMessage: 'For information about compulsory excesses, please refer to your policy documents.'
    },
    notSelected: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.notSelected',
        defaultMessage: 'Not selected'
    },
    added: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.added',
        defaultMessage: 'Added'
    },
    included: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.included',
        defaultMessage: 'Included'
    },
    excluded: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsSummary.excluded',
        defaultMessage: 'Excluded'
    },
    includedAsStandard: {
        id: 'quoteandbind.ngh.views.policy-summary.contents-summary.includedAsStandard',
        defaultMessage: 'Included as standard'
    },
    contentsInteriorDecorationCost: {
        id: 'quoteandbind.ngh.views.policy-summary.contents-summary.contentsInteriorDecorationCost',
        defaultMessage: 'Tenants interior decoration replacement cost'
    },
    contentsInteriorDecorationSelected: {
        id: 'quoteandbind.ngh.views.policy-summary.contents-summary.contentsInteriorDecorationSelected',
        defaultMessage: 'Tenants interior decoration'
    },
    moreThanEight: {
        id: 'quoteandbind.ngh.views.policy-summary.contents-summary.moreThanEight',
        defaultMessage: 'More than 8'
    },
});

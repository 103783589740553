import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'quoteandbind.directives.ExpiredQuoteContent.Sorry your quote has expired',
        defaultMessage: 'Sorry your quote has expired',
    },
    text: {
        id: 'quoteandbind.directives.ExpiredQuoteContent.To start a new quote',
        defaultMessage: 'To start a new quote follow the link below, or alternatively you can call us or arrange a call back.'
    },
    callNumber: {
        id: 'quoteandbind.directives.ExpiredQuoteContact.Call',
        defaultMessage: 'Call 0800 208 8835'
    },
    weekdaysCallWindow: {
        id: 'quoteandbind.directives.ExpiredQuoteContact.Weekdays',
        defaultMessage: 'Monday - Friday: 8am-8pm',
    },
    saturdayCallWindow: {
        id: 'quoteandbind.directives.ExpiredQuoteContact.Saturday',
        defaultMessage: 'Saturday 9am-12:30pm',
    },
    sundayCallWindow: {
        id: 'quoteandbind.directives.ExpiredQuoteContact.Sunday',
        defaultMessage: 'Sunday: Closed',
    },
    callback: {
        id: 'quoteandbind.directives.ExpiredQuoteCallback.Arrange',
        defaultMessage: 'Arrange a call back',
    },
    callbackText: {
        id: 'quoteandbind.directives.ExpiredQuoteCallback.We can call you at',
        defaultMessage: 'We can call you at a time that\'s convenient for you',
    },
    callbackCTA: {
        id: 'quoteandbind.directives.ExpiredQuoteCallback.Book a call back',
        defaultMessage: 'Book a call back',
    },
    homepageButton: {
        id: 'quoteandbind.directives.ExpiredQuoteButtons.Homepage',
        defaultMessage: 'Homepage'
    },
    startNewQuoteButton: {
        id: 'quoteandbind.directives.ExpiredQuoteButtons.Start a new quote',
        defaultMessage: 'Start a new quote',
    },
});

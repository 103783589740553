import React from 'react';
import PropTypes from 'prop-types';
import createHOC from 'react-create-hoc';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

/**
 * Wrapping the Component with error boundary
 * @param {Function} WithErrorBoundary.onError function called on an error
 * @param {boolean} WithErrorBoundary.shouldSetUnhandledRejection  should
 * set window.onunhandledrejection.
 * Default value is set to true. It allows to override the
 * value for the component that is being mounted.
 * @param {boolean} WithErrorBoundary.resetError if the boundary error should be reset
 * @param {Objact} ComponentToWrap component to be wrapped
 * @memberof module:gw-portals-error-react
 * @function withErrorBoundary
 */
const withErrorBoundary = createHOC('withErrorBoundary', (ComponentToWrap, errorBoundaryProps) => {
    function WithErrorBoundary({
        onError, shouldSetUnhandledRejection, resetError, ...props
    }) {
        // arguments defined on the page/component overrides props from parent
        const {
            onError: errorBoundaryOnError = onError,
            shouldSetUnhandledRejection: errorBoundaryShouldOverride = shouldSetUnhandledRejection,
            resetError: errorBoundaryShouldReset = resetError
        } = errorBoundaryProps;
        return (
            <ErrorBoundary
                resetError={errorBoundaryShouldReset}
                onError={errorBoundaryOnError}
                shouldSetUnhandledRejection={errorBoundaryShouldOverride}
            >
                <ComponentToWrap {...props} />
            </ErrorBoundary>
        );
    }

    WithErrorBoundary.propTypes = {
        onError: PropTypes.func,
        shouldSetUnhandledRejection: PropTypes.bool,
        resetError: PropTypes.bool
    };

    WithErrorBoundary.defaultProps = {
        onError: () => <div />, // default behaviour is to ignore the error
        shouldSetUnhandledRejection: false,
        resetError: true
    };

    return WithErrorBoundary;
}, { factory: true, allowExtraProps: true });

export default withErrorBoundary;

import React, { useEffect, useState } from 'react';
import styles from './NfumTimer.module.scss';

function NfumTimer(props) {
    const {
        seconds,
        onTimeEnd
    } = props;
    const [time, setTime] = useState(seconds || 60);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTime((secs) => secs - 1);
        }, 1000);
        return () => clearInterval(timerInterval);
    }, []);

    useEffect(() => {
        if (time === 0) {
            onTimeEnd();
        }
    }, [time, onTimeEnd]);

    const formatTime = (secs) => {
        if (secs <= 0) { return '00:00'; }
        const hours = Math.floor(secs / 3600);
        const min = Math.floor(secs / 60) % 60;
        const sec = secs % 60;
        return [hours, min, sec]
            .map((v) => (`${v}`).padStart(2, '0'))
            .filter((v, i) => v !== '00' || i > 0)
            .join(':');
    };

    return (
        <div className={styles.timerWrapper}>
            <div className={styles.timer}>
                <div className={styles.timerIndicator} />
                <div className={styles.time}>{formatTime(time)}</div>
            </div>
        </div>
    );
}

export default NfumTimer;

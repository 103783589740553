import { defineMessages } from 'react-intl';

export default defineMessages({
    fineArtsCollections: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.fineArtsCollections',
        defaultMessage: 'Fine Arts & Collections'
    },
    fineArtsMessageHeaderContent: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.fineArtsMessageHeader',
        defaultMessage: 'About your fine art and collections'
    },
    fineArtsMessageContent111: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.fineArtsMessageContent111',
        defaultMessage: 'Full details of your fine art and collections are captured in your policy documents.'
    },
    fineArtsMessageContent112: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.fineArtsMessageContent112',
        defaultMessage: 'Click here to View them'
    },
    fineArtsMessageContent211: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.fineArtsMessageContent211',
        defaultMessage: 'If you would like to discuss your fine art and collections with us please'
    },
    fineArtsMessageContent212: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.fineArtsMessageContent212',
        defaultMessage: ' contact your agency'
    },
    unSpecifiedFineArts: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unSpecifiedFineArts',
        defaultMessage: 'Unspecified fine art'
    },
    unspecifiedSingleItemLimit: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.singleItemLimit',
        defaultMessage: 'Single item limit'
    },
    type: {
        id: 'quoteandbind.bespoke.views.fineArtsCollections.type',
        defaultMessage: 'Type'
    },
    replacementCost: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    unspecifiedFineArtPaintings: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unspecifiedFineArtPaintings',
        defaultMessage: 'Pictures, paintings, drawings, etchings, prints and photographs'
    },
    unspecifiedFineArtTapsteries: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unspecifiedFineArtTapsteries',
        defaultMessage: 'Tapesteries and rugs'
    },
    unspecifiedFineArtStatues: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unspecifiedFineArtStatues',
        defaultMessage: 'Statues, sculptures, curios and objets d’art'
    },
    unspecifiedFineArtClocks: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unspecifiedFineArtClocks',
        defaultMessage: 'Clocks, barometers, and mechanical art'
    },
    unspecifiedFineArtPreciousmetals: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unspecifiedFineArtPreciousmetals',
        defaultMessage: 'Precious Metals'
    },
    unspecifiedFineArtBooks: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unspecifiedFineArtBooks',
        defaultMessage: 'Books and manuscripts'
    },
    unspecifiedFineArtPorcelain: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unspecifiedFineArtPorcelain',
        defaultMessage: 'Porcelain, rare glass, and ceramics'
    },
    unspecifiedFineArtAntique: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.unspecifiedFineArtAntique',
        defaultMessage: 'Antique and collectable furniture'
    },
    specifiedFineArts: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.specifiedFineArts',
        defaultMessage: 'Specified fine art'
    },
    agreedValue: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.agreedValue',
        defaultMessage: 'Agreed value'
    },
    collections: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.collections',
        defaultMessage: 'Collections'
    },
    stampsMedalsCoins: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.stampsMedalsCoins',
        defaultMessage: 'Stamps, Medals & Coins'
    },
    sumInsured: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.sumInsured',
        defaultMessage: 'Sum insured'
    },
    wine: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.wine',
        defaultMessage: 'Wine'
    },
    other: {
        id: 'quoteandbind.bespoke.views.quote-summary.fineArtsCollections.other',
        defaultMessage: 'Other'
    },
});

import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { NfumLoader } from 'nfum-components-platform-react';
import { ImageComponent } from 'gw-components-platform-react';
import styles from './BridgeHeaderText.module.scss';

const BridgeHeaderText = (props) => {
    const {
        headerText,
        headerStyle,
        imageStyle,
        textStyle,
        text,
        imageUrl,
        imageAlt
    } = props;

    const headerRef = useRef();
    const imageRef = useRef();
    const textRef = useRef();
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const headerTextClass = cx({
        [styles.headerText]: true,
        [styles.headerTextPaddingLeft]: imageUrl
    });

    const onImageLoad = () => {
        setIsImageLoaded(true);
    };

    const hiddenIfLoading = cx({
        [styles.hidden]: imageUrl && !isImageLoaded
    });

    useEffect(() => {
        if (headerRef && headerRef.current) {
            headerRef.current.setAttribute('style', headerStyle);
        }
        if (imageRef && imageRef.current) {
            imageRef.current.setAttribute('style', imageStyle);
        }
        if (textRef && textRef.current) {
            textRef.current.setAttribute('style', textStyle);
        }
    }, [headerRef, headerStyle, imageStyle, textStyle]);

    return (
        <div className={styles.container}>
            <div className={hiddenIfLoading}>
                <div className={styles.headerContainer}>
                    {imageUrl &&
                        <div className={styles.imageContainer} ref={imageRef}>
                            <ImageComponent
                                className={styles.headerImage}
                                src={imageUrl}
                                alt={imageAlt}
                                onLoad={onImageLoad}
                            />
                        </div>
                    }
                    <h3 className={headerTextClass} ref={headerRef}>{headerText}</h3>
                </div>
                <div className={styles.contentContainer}>
                    <p className={styles.text} ref={textRef}>{text}</p>
                </div>
            </div>
            { (imageUrl && !isImageLoaded) &&
                <div>
                    <NfumLoader />
                </div>
            }
        </div>
    );
};

export default BridgeHeaderText;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { withTracking } from 'gw-portals-tracking-react';
import { Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import kopStyles from './KnockOutPage.module.scss';
import messages from './KnockOutPage.messages';

class KnockOutPage extends Component {
    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                underwritingIssues: PropTypes.arrayOf(PropTypes.shape({}))
            })
        }).isRequired
    };

    static contextType = TranslatorContext;

    constructor(props) {
        super(props);
        this.state = {
            underwritingIssues: '',
            phoneNumber: '',
            redirect: false
        };
    }

    componentDidMount() {
        const {
            location: {
                state: { underwritingIssues }
            }
        } = this.props;
        this.setState({ underwritingIssues: underwritingIssues, phoneNumber: config.supportPhone });
    }

    getLongDescription = (underwritingIssues) => {
        if (underwritingIssues) {
            return underwritingIssues.map(
                ({ currentBlockingPoint, description, longDescription }) => {
                    if (currentBlockingPoint === 'Rejected') {
                        return (
                            <span className={kopStyles.gwKnockOutDescription}>
                                {description || longDescription}
                            </span>
                        );
                    }
                    return null;
                }
            );
        }
        return null;
    };

    navigateTo = () => {
        this.setState({
            redirect: true
        });
    };

    redirectTo = () => {
        this.setState({
            redirect: false
        });
        return <Redirect to="/" />;
    };

    render() {
        const translator = this.context;
        const { underwritingIssues, phoneNumber, redirect } = this.state;
        const applications = ['producer', 'csr'];
        const gatewayQuote = applications.includes(config.persona);
        const titleMessage = gatewayQuote ? 'sorryInformation' : 'thankyouInterest';
        const moreInfo = gatewayQuote ? 'forMoreInfo' : 'giveUsCall';
        return (
            <div className={kopStyles.gwKnockOutContainer}>
                <div className={kopStyles.gwKnockOutPage}>
                    <div className={kopStyles.gwKnockOutIcon}>
                        <i className="mi-phone" />
                    </div>
                    <h2 className={kopStyles.gwKnockOutTitle}>
                        {translator(messages[titleMessage])}
                    </h2>
                    <div>
                        {underwritingIssues !== '' || underwritingIssues !== undefined
                            ? this.getLongDescription(underwritingIssues)
                            : null}
                    </div>
                    <div className={kopStyles.gwKnockOutMoreInfo}>
                        {translator(messages[moreInfo], { phoneNumber: phoneNumber })}
                    </div>
                    {redirect ? this.redirectTo() : null}
                    <div className={kopStyles.gwKnockOutButton}>
                        <Button onClick={this.navigateTo}>
                            {translator(messages.backToHomepage)}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withTracking(KnockOutPage));

import React, {
    useState, useCallback, useContext, useEffect, useRef
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { NfumAccordionHeader } from 'nfum-components-platform-react';
import { QB_STEPS } from 'nfum-portals-utils-react';
import styles from './PreQualificationsPage.module.scss';
import messages from './PreQualificationsPage.messages';
import metadata from './PreQualificationsPage.metadata.json5';
import ReferralMessage from '../../components/ReferralMessageComponent/ReferralMessageComponent';
import HardStopMessage from '../../components/HardStopMessage/HardStopMessage';
import useTagManager from '../../hooks/useTagManager';

function PreQualificationsPage(props) { /* NOSONAR: pure declarative usage */
    const translator = useContext(TranslatorContext);
    const {
        wizardData: submissionVM,
        updateWizardData,
        goNext,
        isSkipping
    } = props;
    const {
        initialValidation,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('PreQualificationsPage');
    const [isPageInitialized, setPageInitialized] = useState(false);
    const ref = useRef();
    const [data, setData] = useState({
        showAboutYouContent: true,
        showAboutHome: true,
        showAboutHomeContent: false,
        showContinueButtonAndMessage: false,
        isAboutYouConfirmed: null,
        isAboutYourHomeConfirmed: null,
        isClaimsAndLossHistoryConfirmed: null
    });
    const groupsKeys = Object.freeze({
        ABOUT_YOU: 'AboutYou',
        CLAIMS_AND_LOSS_HISTORY: 'ClaimsAndLossHistory',
        ABOUT_YOUR_HOME: 'AboutYourHome'
    });
    const { pushFormStepInfo, pushAccordionOpenInfo } = useTagManager();

    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');

    // #region GTM EVENTS
    useEffect(() => {
        if (isSkipping) {
            initialValidation().then((skip) => {
                if (!skip) {
                    pushFormStepInfo(submissionVM, QB_STEPS.PREQUALIFICATION);
                }
            });
        } else {
            pushFormStepInfo(submissionVM, QB_STEPS.PREQUALIFICATION);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data.showAboutHomeContent === true) {
            if (isSkipping) {
                initialValidation().then((skip) => {
                    if (!skip) {
                        pushAccordionOpenInfo(translator(messages.AboutHomeTitle));
                    }
                });
            } else {
                pushAccordionOpenInfo(translator(messages.AboutHomeTitle));
            }
        }
    }, [
        pushAccordionOpenInfo,
        data.showAboutHomeContent,
        initialValidation,
        isSkipping,
        translator
    ]);

    useEffect(() => {
        if (data.showAboutYouContent === true) {
            if (isSkipping) {
                initialValidation().then((skip) => {
                    if (!skip) {
                        pushAccordionOpenInfo(translator(messages.AboutYouTitle));
                    }
                });
            } else {
                pushAccordionOpenInfo(translator(messages.AboutYouTitle));
            }
        }
    }, [
        pushAccordionOpenInfo,
        data.showAboutYouContent,
        initialValidation,
        isSkipping,
        translator
    ]);
    // #endregion GTM EVENTS

    const skipWhenRetrieved = useCallback(() => {
        return _.get(submissionVM.value, 'quoteID') !== undefined;
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(skipWhenRetrieved);
    }, [skipWhenRetrieved, registerInitialComponentValidation]);

    useEffect(() => {
        if (
            _.isEmpty(submissionVM.lobData.homeLine.coverables.homhomeProperty.value)
        ) {
            submissionVM.lobData.homeLine.coverables.homhomeProperty = {};
            updateWizardData(submissionVM);
        }
        setPageInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdatingState = useCallback((nextState) => {
        setData({ ...data, ...nextState });
    }, [data]);

    const onEditDiv = (value, isOpen) => {
        const {
            isAboutYouConfirmed,
            isAboutYourHomeConfirmed,
            isClaimsAndLossHistoryConfirmed
        } = data;
        switch (value) {
            case groupsKeys.ABOUT_YOU:
                handleUpdatingState({
                    showAboutYouContent: !isOpen,
                    isAboutYouConfirmed: true,

                    showAboutHomeContent: !isAboutYourHomeConfirmed && isOpen,

                    showContinueButtonAndMessage:
                        isAboutYouConfirmed
                        && isAboutYourHomeConfirmed,
                });
                break;
            case groupsKeys.CLAIMS_AND_LOSS_HISTORY:
                handleUpdatingState({

                    showAboutHomeContent: !isAboutYourHomeConfirmed && isOpen,
                    showAboutYouContent: !isAboutYouConfirmed && isOpen,

                    showContinueButtonAndMessage:
                        isAboutYouConfirmed
                        && isAboutYourHomeConfirmed
                        && isClaimsAndLossHistoryConfirmed,
                });
                break;
            case groupsKeys.ABOUT_YOUR_HOME:
                handleUpdatingState({
                    showAboutHomeContent: !isOpen,
                    isAboutYourHomeConfirmed: true,
                    showAboutYouContent: !isAboutYouConfirmed && isOpen,

                    showContinueButtonAndMessage:
                        isAboutYouConfirmed
                        && isAboutYourHomeConfirmed,
                });
                break;
            default:
                break;
        }
    };

    const renderAccordionHeader = (id, title, isOpen, isEditButtonVisible) => {
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                showCloseMessage={isEditButtonVisible}
                openMessage={messages.EditIcon}
                title={title}
                icon="edit"
                onClick={isEditButtonVisible ? () => onEditDiv(id, isOpen) : () => {
                    // do nothing
                }}
            />
        );
    };

    const handleAboutYouConfirmationChange = useCallback((isConfirmed) => {
        if (isConfirmed) {
            window.localStorage.removeItem('sessionTimeOut');
            handleUpdatingState({
                showAboutYouContent: true,
                showAboutHome: true,
                showAboutHomeContent: true,
                isAboutYouConfirmed: true
            });
        } else {
            window.localStorage.removeItem('sessionTimeOut');
            handleUpdatingState({
                showAboutHome: false,
                isAboutYouConfirmed: false,
                showContinueButtonAndMessage: false
            });
        }
    }, [handleUpdatingState]);

    const handleAboutYouConfirmationBlur = useCallback((isConfirmed) => {
        if (data.isAboutYouConfirmed === true) {
            handleUpdatingState({
                showAboutYouContent: false,
                showAboutHome: true,
                showAboutHomeContent: true,
                isAboutYouConfirmed: true
            });
        } else {
            handleUpdatingState({
                showAboutHome: false,
                isAboutYouConfirmed: false,
                showContinueButtonAndMessage: false
            });
        }
    }, [handleUpdatingState]);

    const handleAboutYourHomeConfirmationChange = useCallback((isConfirmed) => {
        if (isConfirmed) {
            handleUpdatingState({
                showAboutHomeContent: true,
                showContinueButtonAndMessage: true,
                isAboutYourHomeConfirmed: true
            });
        } else {
            handleUpdatingState({
                isAboutYourHomeConfirmed: false,
                showContinueButtonAndMessage: false
            });
        }
    }, [handleUpdatingState]);

    const handleAboutYourHomeConfirmationBlur = useCallback((isConfirmed) => {
        if (isConfirmed) {
            handleUpdatingState({
                showAboutHomeContent: false,
                showContinueButtonAndMessage: true,
                isAboutYourHomeConfirmed: true
            });
        } else {
            handleUpdatingState({
                isAboutYourHomeConfirmed: false,
                showContinueButtonAndMessage: false
            });
        }
    }, [handleUpdatingState]);

    const onNext = useCallback(async () => {
        if (submissionVM.value.isBespokeHomeInsurance_NFUM) {
            _.set(submissionVM.value, 'lobData.homeLine.isBespokeSustainedDmg', false);
            _.set(submissionVM.value, 'lobData.homeLine.isBespokeFloodRisk', false);
        } else {
            _.set(submissionVM.value, 'lobData.homeLine.isNghSustainedDmg', false);
            _.set(submissionVM.value, 'lobData.homeLine.isNghFloodRisk', false);
        }
        /** US 631090 - Commented below mwthod for security reasons */
        /* const accountNumber = _.get(submissionVM.value, 'baseData.accountNumber');
        const crmGUID = _.get(submissionVM, 'baseData.crmGUID');
        if (accountNumber) {
            try {
                const accDetails = await LoadSaveService.fetchAccountDetailsNFUM(
                    accountNumber,
                    authHeader
                );
                if (accDetails) {
                    _.set(submissionVM.value, 'baseData.accountHolder', accDetails);
                    _.set(submissionVM.value, 'baseData.accountHolder.prePopulateData', true);
                    updateWizardData(submissionVM);
                }
            } catch (error) {
                return goNext();
            }
        } else if (crmGUID) {
            try {
                const crmDetails = await LoadSaveService.fetchCRMCustomerDetailsNFUM(
                    crmGUID,
                    authHeader
                );
                if (crmDetails) {
                    _.set(submissionVM.value, 'baseData.accountHolder', crmDetails);
                    _.set(submissionVM.value, 'baseData.accountHolder.prePopulateData', true);
                    updateWizardData(submissionVM);
                }
            } catch (error) {
                return goNext();
            }
        }*/
        return goNext();
    }, [goNext, submissionVM]);

    const overrideProps = {
        aboutYouHeading: {
            content: renderAccordionHeader(groupsKeys.ABOUT_YOU,
                translator(messages.AboutYouTitle),
                data.showAboutYouContent,
                data.isAboutYouConfirmed),
        },
        pageContainer: {
            ref: ref
        },
        aboutHomeHeading: {
            content: renderAccordionHeader(groupsKeys.ABOUT_YOUR_HOME,
                translator(messages.AboutHomeTitle),
                data.showAboutHomeContent,
                data.isAboutYourHomeConfirmed),
        },
        aboutYouContent: {
            visible: data.showAboutYouContent,
        },
        aboutHome: {
            visible: data.showAboutHome
                || (!data.isAboutYourHomeConfirmed && data.isAboutYouConfirmed)
        },
        aboutHomeContent: {
            visible: data.showAboutHomeContent,
        },
        referralMessageContainer: {
            visible: data.isAboutYouConfirmed !== false
            && (data.isClaimsAndLossHistoryConfirmed === false
                || data.isAboutYourHomeConfirmed === false)
        },
        referralMessage: {
            title: translator(messages.referralTitle),
            description: translator(messages.referralDescription),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        hardStopMessageContainer: {
            visible: data.isAboutYouConfirmed === false
        },
        aboutYouQuoteConfirmationRadioButton: {
            onValueChange: handleAboutYouConfirmationChange,
            onBlur: handleAboutYouConfirmationBlur
        },
        yourHomeQuoteConfirmationRadioButton: {
            onValueChange: handleAboutYourHomeConfirmationChange
        },
        byClickingContinueContainer: {
            visible: data.isAboutYourHomeConfirmed === true
                && data.isAboutYouConfirmed === true
        },
        buttonsContainer: {
            visible: data.isAboutYourHomeConfirmed === true
                && data.isAboutYouConfirmed === true
        },
        continueButton: {
            onClick: onNext
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            referralMessage: ReferralMessage,
            hardStopMessage: HardStopMessage
        },
        resolveCallbackMap: {
            onNext: onNext
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            showPrevious={false}
            showCancel={false}
            showNext={false}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                model={data}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PreQualificationsPage.propTypes = wizardProps;
export default PreQualificationsPage;

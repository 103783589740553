import { useErrorHandler } from 'nfum-portals-utils-react';
import useCms from './useCms';

const useCommonCms = (marketingInfo, setMarketingInfo) => {
    const { handleNotBlockingError } = useErrorHandler();
    const COMMON_URL_PREFIX = '/bridge/quote-and-buy/common';
    const { createCmsSiteMapPromise, createCmsContentPromise } = useCms();

    const COMMON_CMS_CONFIG = [
        // Common
        {
            productId: 'common',
            portalPageId: 'retrieve-quote',
            cmsPageId: 'retrieve-quote'
        },
        {
            productId: 'common',
            portalPageId: 'need-help',
            cmsPageId: 'need-help'
        }
    ];

    const getCmsContentForCommon = (productCode) => {
        createCmsSiteMapPromise().then((siteMapInfo) => {
            const allPagesPromises = [];
            COMMON_CMS_CONFIG
                .filter((configItem) => configItem.productId === productCode)
                .forEach((page) => {
                    const siteId = siteMapInfo?.find((site) => site.url === `${COMMON_URL_PREFIX}/${productCode}/${page.cmsPageId}/`)?.id;
                    if (siteId) {
                        allPagesPromises.push(createCmsContentPromise(siteId));
                    }
                });
            const newMarketingInfo = {};
            Promise.all(allPagesPromises).then((allPagesCmsContent) => {
                allPagesCmsContent.forEach((pageContent) => {
                    COMMON_CMS_CONFIG
                        ?.filter((page) => page.cmsPageId === pageContent.routeSegment
                            && page.productId === productCode).forEach((configItem) => {
                            newMarketingInfo[configItem.portalPageId] = pageContent;
                        });
                });
                setMarketingInfo({
                    ...marketingInfo,
                    ...newMarketingInfo
                });
            });
        }).catch((err) => {
            handleNotBlockingError(err);
        });
    };

    return {
        getCmsContentForCommon
    };
};

export default useCommonCms;

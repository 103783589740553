import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './VehicleComponent.metadata.json5';
import './VehicleComponent.messages';

function VehicleComponent(props) {
    const {
        data: vehicleVM,
        readOnlyFields,
        phoneWide,
        labelPosition,
        showOptional,
        path,
        id,
        index,
        onValidate,
        onValueChange
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, vehicleVM]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const newPath = changedPath.replace(/^vehicle\./, '');
            const fullPath = `${path}.${newPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide
        },
        [`vinlookup${index}`]: { readOnly: _.includes(readOnlyFields, 'vinlookup') },
        [`vehicleLicencePlate${index}`]: { readOnly: _.includes(readOnlyFields, 'vehicleLicencePlate') },
        [`driverLicenceState${index}`]: { readOnly: _.includes(readOnlyFields, 'driverLicenceState') },
        [`vehicleCostNew${index}`]: { readOnly: _.includes(readOnlyFields, 'vehicleCostNew') }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    const data = {
        vehicle: vehicleVM
    };
    return (
        <ViewModelForm
            model={data}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

VehicleComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    readOnlyFields: PropTypes.arrayOf(PropTypes.string),
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string.isRequired
};
VehicleComponent.defaultProps = {
    data: {},
    labelPosition: 'left',
    phoneWide: {
        labelPosition: 'top'
    },
    path: undefined,
    readOnlyFields: [],
    showOptional: true,
    index: 0
};
export default VehicleComponent;

/* eslint-disable */
import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@jutro/layout';
import { RadioButtonField } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';

const NfumRadioButtonField = forwardRef((props, ref) => {

    const hasTwoOptions = props.availableValues.length === 2;
    const className = classNames({ ["yesNoRadioButton"]: hasTwoOptions })

    useEffect(() => {
        const radioButtonElements = document.querySelectorAll('.jut__RadioButton__primaryText');
        if (radioButtonElements.length > 0) {
            radioButtonElements.forEach((element) => {
                element.setAttribute('role', 'radiogroup');
            });
        }
    }, []);

    return (
        <div ref={ref}>
            <NfumFieldLabel
                {...props}
            />
            <div>
                <Grid
                    hgap="none"
                    className={className}
                >
                    <RadioButtonField
                        {...props}
                        hideLabel="true"
                    />
                </Grid>
            </div>
        </div>
    );
})

export default NfumRadioButtonField;

import { defineMessages } from 'react-intl';

export default defineMessages({
    returnToWebsite: {
        id: 'quoteandbind.ngh.views.success.Return to website',
        defaultMessage: 'Return to website'
    },
    thankYouWithPaperDocuments: {
        id: 'quoteandbind.ngh.views.success.thankYouWithPaperDocuments',
        defaultMessage: 'Thank you for purchasing your home insurance policy from NFU Mutual. A paper copy of your policy documents will be sent you in the post shortly. Your policy documents are available in your online account which you can create below.'
    },
    thankYouWithoutPaperDocuments: {
        id: 'quoteandbind.ngh.views.success.thankYouWithoutPaperDocuments',
        defaultMessage: 'Thank you for purchasing your Home Insurance policy from NFU Mutual. Your policy documents will be available in your online account which you can create below.'
    },
    thankYouWithPaperDocumentsForLifestyle: {
        id: 'quoteandbind.ngh.views.success.thankYouWithPaperDocumentsForLifestyle',
        defaultMessage: 'Thank you for choosing NFU Mutual for your Insurance policy. A paper copy of your policy documents will be sent you in the post shortly.'
    },
    thankYouWithoutPaperDocumentsForLifestyle: {
        id: 'quoteandbind.ngh.views.success.thankYouWithoutPaperDocumentsForLifestyle',
        defaultMessage: 'Thank you for choosing NFU Mutual for your Insurance policy.'
    },
    transactionTitle: {
        id: 'quoteandbind.ngh.views.success.transactionTitle',
        defaultMessage: 'Your transaction was successful'
    },
    transactionDDTitle: {
        id: 'quoteandbind.ngh.views.success.transactionDDTitle',
        defaultMessage: 'Your Direct Debit details have been accepted'
    },
    transactionSupplimentaryTitle: {
        id: 'quoteandbind.ngh.views.success.transactionSupplimentaryTitle',
        defaultMessage: 'Your card payment has been taken and your Direct Debit details have been accepted'
    },
    YourOnlineAccount: {
        id: 'quoteandbind.ngh.views.success.YourOnlineAccount',
        defaultMessage: 'Why register for an online account?'
    },
    YourRightsConditions: {
        id: 'quoteandbind.ngh.views.success.Your rights and conditions',
        defaultMessage: 'Your rights and conditions'
    },
    coolingPeriod: {
        id: 'quoteandbind.ngh.views.success.coolingPeriod',
        defaultMessage: 'Cooling off period'
    },
    coolingPeriodText: {
        id: 'quoteandbind.ngh.views.success.coolingPeriodText',
        defaultMessage: 'If you choose not to go ahead with this policy, you may cancel by contacting us within 14 days of receiving the policy. We’ll charge you a proportion of the premium for the period during which the policy was in force and we’ll refund the remaining premium as long as no claims have been made against the policy.'
    },
    cancellationRightsText1: {
        id: 'quoteandbind.ngh.views.success.cancellationRightsText1',
        defaultMessage: 'You can cancel your policy at any other time by calling or writing to us, and we won’t charge a cancellation fee.  We’ll refund the premium for the period after the cancellation date.  We won’t refund any premium if you’ve made a claim.'
    },
    cancellationRights: {
        id: 'quoteandbind.ngh.views.success.cancellationRights',
        defaultMessage: 'Your cancellation rights'
    },
    anythingChangesText: {
        id: 'quoteandbind.ngh.views.success.anythingChangesText',
        defaultMessage: 'Let us know if anything changes'
    },
    significantChanges: {
        id: 'quoteandbind.ngh.views.success.significantChanges',
        defaultMessage: 'You must tell us in the future of any changes to the information you’ve provided, or it may affect your insurance.'
    },
    finalDescriptionInfo: {
        id: 'quoteandbind.ngh.views.success-page.finalDescriptionInfo',
        defaultMessage: 'NFU Mutual Home Insurance underwritten by NFU Mutual'
    },
    accountStatusRegistered: {
        id: 'quoteandbind.ngh.views.success.accountStatusRegistered',
        defaultMessage: 'Your online account'
    },
    accountStatusInvitedOrIncomplete: {
        id: 'quoteandbind.ngh.views.success.accountStatusInvitedOrIncomplete',
        defaultMessage: 'Register for your online account'
    },
    accountStatusEligibleReady: {
        id: 'quoteandbind.ngh.views.success.accountStatusEligibleReady',
        defaultMessage: 'Register for your online account'
    },
    accountStatusEligible: {
        id: 'quoteandbind.ngh.views.success.accountStatusEligible',
        defaultMessage: 'Want to manage your policy online?'
    },
    contactUsHeader: {
        id: 'quoteandbind.ngh.views.success.contactUsHeader',
        defaultMessage: 'Need to get in touch?'
    },

});

import { defineMessages } from 'react-intl';

export default defineMessages({
    futureStateInsurance: {
        id: 'facebook.share-dialog.FutureState Insurance',
        defaultMessage: 'FutureState Insurance',
    },
    caption: {
        id: 'facebook.share-dialog.The FutureState Insurance company',
        defaultMessage: 'The FutureState Insurance company',
    },
    description: {
        id: 'facebook.share-dialog.I just got a great rate on my policy!',
        defaultMessage: 'I just got a great rate on my policy!',
    },
    facebookMessageShare: {
        id: 'platform.facebook.Share',
        defaultMessage: 'Share'
    }
});

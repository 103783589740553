import React from 'react';
import {
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import {
    ModalNext,
} from '@jutro/components';
import metadataVariant1 from './NfumConfirmModal.variant1.metadata.json5';
import metadataVariant2 from './NfumConfirmModal.variant2.metadata.json5';
import metadataVariant3 from './NfumConfirmModal.variant3.metadata.json5';
import metadataVariant5 from './NfumConfirmModal.variant5.metadata.json5';
import styles from './NfumConfirmModal.module.scss';
import './NfumConfirmModal.messages';

function NfumConfirmModal(props) {
    const {
        isOpen,
        onResolve,
        variant
    } = props;

    const handleClose = (data) => () => {
        document.activeElement.focus();
        onResolve(data);
    };

    const overrideProps = {

    };

    const resolveVariantComponentMetadata = () => {
        if (variant === 'variant2') {
            return metadataVariant2.componentContent;
        }
        if (variant === 'variant3') {
            return metadataVariant3.componentContent;
        }
        if (variant === 'variant5') {
            return metadataVariant5.componentContent;
        }
        return metadataVariant1.componentContent;
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onConfirm: handleClose({ type: 'CONFIRM' }),
            onCancel: handleClose({ type: 'CANCEL' })
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={() => handleClose({ type: 'CANCEL' })}
        >
            <ViewModelForm
                className={styles.modalWrapper}
                uiProps={resolveVariantComponentMetadata()}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </ModalNext>
    );
}

export default NfumConfirmModal;

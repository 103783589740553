import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { NfumLoader } from 'nfum-components-platform-react';
import { ImageComponent } from 'gw-components-platform-react';
import { Button } from '@jutro/components';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import styles from './BridgeImageTextHyperlink.module.scss';

const BridgeImageTextHyperlink = (props) => {
    const {
        headerText,
        headerStyle,
        imageStyle,
        textStyle,
        imageUrl,
        linkUrl,
        text,
        imageAlt,
        linkText
    } = props;

    const headerRef = useRef();
    const imageRef = useRef();
    const textRef = useRef();

    const {
        pushLinkClickInfo
    } = useCommonTagManager();
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const onLinkClicked = () => {
        pushLinkClickInfo(linkText, linkUrl);
        window.open(linkUrl, '_blank');
    };

    const onImageLoad = () => {
        setIsImageLoaded(true);
    };

    const hiddenIfLoading = cx({
        [styles.hidden]: !isImageLoaded
    });

    useEffect(() => {
        if (headerRef && headerRef.current) {
            headerRef.current.setAttribute('style', headerStyle);
        }
        if (imageRef && imageRef.current) {
            imageRef.current.setAttribute('style', imageStyle);
        }
        if (textRef && textRef.current) {
            textRef.current.setAttribute('style', textStyle);
        }
    }, [headerRef, headerStyle, imageStyle, textStyle]);

    return (
        <div className={styles.container}>
            <div className={hiddenIfLoading}>
                { headerText &&
                    <div className={styles.headerContainer}>
                        <h3 className={styles.headerText} ref={headerRef}>{headerText}</h3>
                    </div>
                }
                {imageUrl &&
                    <div className={styles.imageContainer} ref={imageRef}>
                        <ImageComponent
                            className={styles.image}
                            src={imageUrl}
                            alt={imageAlt}
                            onLoad={onImageLoad}
                        />
                    </div>
                }
                <div className={styles.contentContainer}>
                    <p className={styles.text} ref={textRef}>{text}</p>
                </div>
                { linkText && linkUrl
                    && (
                        <div className={styles.buttonContainer}>
                            <Button
                                className={styles.button}
                                id="nextButton"
                                type="text"
                                icon="gw-keyboard-arrow-right"
                                iconPosition="right"
                                onClick={() => onLinkClicked()}
                            >
                                {linkText}
                            </Button>
                        </div>
                    )
                }
            </div>
            {!isImageLoaded &&
                <div>
                    <NfumLoader />
                </div>
            }
        </div>
    );
};

export default BridgeImageTextHyperlink;

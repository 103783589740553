/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, DropdownSelectField } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';

import styles from './NfumDropdownSelectField.module.scss';

function NfumDropdownSelectField(props) {
    const {
        value,
        success,
        error,
        showEdit,
        onValidationChange,
        label,
        id
    } = props;
    const [currentValidation, updateCurrentValidation] = useState(true);
    const [showState, updateShowState] = useState(false);

    const showError = value !== undefined && (error || !currentValidation);
    let icon;
    
    if (showError) {
        icon = "gw-close";
    } else if (success || value) {
        icon = "gw-check";
    } else {
        icon = undefined;
    }
    const iconClass = classNames(styles.nfumInputIcon, {
        [styles.errorIcon]: showError
    });

    const iconComponent = <Icon icon={icon} className={iconClass}/>;

    const onValidate = (isValid, path) => {
        updateCurrentValidation(isValid);
        onValidationChange(isValid, path);
    };

    const onBlur = () => {
        updateShowState(true);
    };

    const internalClassNames={
        selectIcon: "nfumDropdownOptionSelectIcon",
        option: "nfumDropdownOption"
    }

    const containerClasses = classNames(styles.nfumInputContainer, {
        [`${id}DropdownContainer`]: true
    });

    const dropdownInput = document.querySelector(`.${id}DropdownContainer input`);
    if (dropdownInput) {
        dropdownInput.setAttribute('aria-labelledby', id);
    }
    
    return (
        <div className={containerClasses}>
            { label && (
                <NfumFieldLabel
                    {...props}
                />
            )}
            <div className={styles.nfumInput}>
                <DropdownSelectField
                    {...props}
                    icon={showEdit && "gw-edit"}
                    iconPosition="right"
                    className={styles.nfumInputField}
                    onValidationChange={onValidate}
                    onBlur={onBlur}
                    hideLabel="true"
                    internalClassNames={internalClassNames}
                />
                {(showState) && iconComponent}
            </div>
        </div>
    );
}

NfumDropdownSelectField.propTypes = {
    error: PropTypes.bool,
    success: PropTypes.bool,
    showEdit: PropTypes.bool,
};

NfumDropdownSelectField.defaultProps = {
    error: false,
    success: false,
    showEdit: false
};

export default NfumDropdownSelectField;

import React from 'react';
import PropTypes from 'prop-types';
import createHOC from 'react-create-hoc';
import useErrors from '../hooks/useErrors';

/**
 * Adds error props to the ComponentToWrap
 * @memberof module:gw-portals-error-react
 * @function withErrors
 * @param {Object} ComponentToWrap - component to be wrapped with error props
 * @returns {Object} - component wrapped with error props
 */
export const withErrors = createHOC('withErrorBoundary', (ComponentToWrap) => {
    return function WithErrors(props) {
        const errorProps = useErrors();

        return <ComponentToWrap {...props} {...errorProps} />;
    };
}, { factory: false });

export default withErrors;

/**
 * React property types definition map for error
 * @alias errorPropTypes
 * @memberof module:gw-portals-error-react
 * @type {Object}
 */
export const errorPropTypes = {
    throwReactError: PropTypes.func.isRequired,
};

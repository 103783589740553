import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { ImageComponent } from 'gw-components-platform-react';
import styles from './BridgeLoadingBox.module.scss';

const BridgeLoadingBox = (props) => {
    const {
        headerText,
        headerStyle,
        imageStyle,
        textStyle,
        imageUrl,
        imageAlt,
        text
    } = props;

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const headerRef = useRef();
    const imageRef = useRef();
    const textRef = useRef();

    const onImageLoad = () => {
        setIsImageLoaded(true);
    };

    const containerStyle = cx({
        [styles.container]: true,
        [styles.hidden]: !isImageLoaded
    });

    useEffect(() => {
        if (headerRef && headerRef.current) {
            headerRef.current.setAttribute('style', headerStyle);
        }
        if (imageRef && imageRef.current) {
            imageRef.current.setAttribute('style', imageStyle);
        }
        if (textRef && textRef.current) {
            textRef.current.setAttribute('style', textStyle);
        }
    }, [headerRef, headerStyle, imageStyle, textStyle]);

    return (
        <div className={containerStyle}>
            <div>
                <div className={styles.headerContainer}>
                    <p className={styles.headerText} ref={headerRef}>{headerText}</p>
                </div>
                {imageUrl &&
                    <div className={styles.imageContainer} ref={imageRef}>
                        <ImageComponent
                            className={styles.image}
                            src={imageUrl}
                            alt={imageAlt}
                            onLoad={onImageLoad}
                        />
                    </div>
                }
                <div className={styles.contentContainer}>
                    <p className={styles.text} ref={textRef}>{text}</p>
                </div>
            </div>
        </div>
    );
};

export default BridgeLoadingBox;
